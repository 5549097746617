import { FC } from "react";
import CollapsablePanel from "../../../components/CollapsablePanel/CollapsablePanel";
import SearchResult from "../../../components/SearchResult/SearchResult";
import Panel from "../../../components/Panel/Panel";
import styles from "../SearchResultsView.module.scss";
import { parseLanguageObject } from "../../../utils/parseLanguageObject";
import useResponsive from "../../../hooks/useResponsive";
import { FeatureQueryResponse } from "../../../redux/services/types";
import classNames from "classnames";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

interface Props {
  data: FeatureQueryResponse[] | undefined;
  onClickSearchResult: (id: string, name: string) => void;
  firstSearchResultRef: React.RefObject<HTMLDivElement>;
  isFetching: boolean;
}

const SearchResultList: FC<Props> = ({
  data,
  onClickSearchResult,
  firstSearchResultRef,
  isFetching,
}) => {
  const { Default, Mobile } = useResponsive();
  const { t } = useTranslation();

  const { language } = useAppSelector((state) => state.application);

  const resultsCountString = data?.length
    ? data?.length > 1
      ? t("search_results.results_heading", { number: data?.length })
      : t("search_results.single_result_heading", { number: data?.length })
    : null;

  const searchResultProps = ({
    information,
    street_address,
    media,
    is_temporarily_closed,
    id,
  }: FeatureQueryResponse) => {
    const name = parseLanguageObject(information.long_name, language) || "";

    return {
      dataQA: "search-result",
      address: street_address,
      description: parseLanguageObject(information.summary, language),
      imageUrl: media.popup ? media.popup.url : "",
      isTemporarilyClosed: is_temporarily_closed,
      operatingHours: information.operating_hours,
      title: name,
      onClick: () => onClickSearchResult(id, name),
    };
  };

  const content = (
    <div className={styles.searchResultsContainer}>
      {data && data.length && !isFetching ? (
        data.map((feature, index) => (
          <div className={styles.searchResult} key={feature.id}>
            <SearchResult
              {...(index === 0 && { ref: firstSearchResultRef })}
              {...searchResultProps(feature)}
            />
          </div>
        ))
      ) : (
        <div className={styles.loadingSearchResult}>
          <SearchResult
            dataQA="loading-search-result"
            isLoading
            ref={firstSearchResultRef}
          />
        </div>
      )}
    </div>
  );

  return !data || (data && data.length) || isFetching ? (
    <>
      {Default && (
        <CollapsablePanel
          dataQA="search-results-panel"
          header={
            !isFetching && !data?.length ? null : (
              <div className={styles.searchResultsHeader}>
                <div className={styles.results}>
                  {isFetching ? (
                    <div
                      className={classNames(
                        styles.loadingResults,
                        styles.skeletonBox,
                      )}
                    />
                  ) : (
                    resultsCountString
                  )}
                </div>
              </div>
            )
          }
          isOpen
          separator
        >
          {content}
        </CollapsablePanel>
      )}
      {Mobile && content}
    </>
  ) : (
    <>
      {Default && (
        <Panel dataQA="no-search-results-panel">
          <h3 className={styles.noResultsTitle}>
            {t("search_results.no_results_title")}
          </h3>
          <p className={styles.noResultsDescription}>
            {t("search_results.no_results_body")}
          </p>
        </Panel>
      )}
      {Mobile && (
        <div ref={firstSearchResultRef}>
          <h3 className={styles.noResultsTitle}>
            {t("search_results.no_results_title")}
          </h3>
          <p className={styles.noResultsDescription}>
            {t("search_results.no_results_body")}
          </p>
        </div>
      )}
    </>
  );
};

export default SearchResultList;
