import LocatorAPI, { Ping } from "./LocatorAPI";

const DEFAULT_POSITION = {
  timestamp: 0,
  coords: {
    latitude: 0,
    longitude: 0,
    accuracy: 1,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  },
};

function pingToGeolocationPosition(ping: Ping): GeolocationPosition {
  return {
    timestamp: Date.parse(ping.timestamp),
    coords: {
      latitude: ping.position.latitude,
      longitude: ping.position.longitude,
      accuracy: ping.accuracy || 1,
      altitude: ping.metadata?.altitude
        ? parseFloat(ping.metadata.altitude)
        : null,
      altitudeAccuracy: ping.metadata?.altitudeAccuracy
        ? parseFloat(ping.metadata.altitudeAccuracy)
        : null,
      heading: ping.heading || null,
      speed: ping.metadata?.speed ? parseFloat(ping.metadata.speed) : null,
    },
  };
}

interface Watch {
  successCallback: PositionCallback;
  errorCallback?: PositionErrorCallback | null;
}

interface ProviderInitOptions {
  intervalMs: number;
}

export class LocatorGeolocationProvider implements Geolocation {
  private static readonly DEFAULT_PROVIDER_INIT_OPTIONS: ProviderInitOptions = {
    intervalMs: 1000,
  };

  private readonly locatorApi: LocatorAPI;
  private readonly locatorProject: string;
  private readonly assetUuid: string;
  private readonly intervalMs: number;

  private watches: Map<number, Watch> = new Map();
  private nextWatchId = 1;

  private intervalId?: NodeJS.Timeout | null;

  constructor(
    locatorApi: LocatorAPI,
    locatorProject: string,
    assetUuid: string,
    opts?: Partial<ProviderInitOptions>,
  ) {
    const options = {
      ...LocatorGeolocationProvider.DEFAULT_PROVIDER_INIT_OPTIONS,
      ...opts,
    };

    this.intervalMs = options.intervalMs;

    this.locatorApi = locatorApi;
    this.locatorProject = locatorProject;
    this.assetUuid = assetUuid;
  }

  // indempotent
  private async initialisePositionReplay(): Promise<void> {
    if (this.intervalId !== undefined && this.intervalId !== null) return;

    try {
      const pings = await this.locatorApi.getAssetPings(
        this.locatorProject,
        this.assetUuid,
      );
      let replayIndex = 0;

      this.intervalId = setInterval(() => {
        if (replayIndex >= pings.length) {
          this.intervalId && clearInterval(this.intervalId);
          return;
        }
        const ping = pings[replayIndex];
        replayIndex++;
        const geolocationPosition = pingToGeolocationPosition(ping);
        [...this.watches.values()].forEach((watch) => {
          watch.successCallback(geolocationPosition);
        });
      }, this.intervalMs);
    } catch (e) {
      console.warn(`Error when getting replay position ${e}`);

      const geoError = {
        code: GeolocationPositionError.POSITION_UNAVAILABLE,
        message: "Could not get replay position",
      } as GeolocationPositionError;

      [...this.watches.values()].forEach((watch) => {
        watch.errorCallback
          ? watch.errorCallback(geoError)
          : console.warn("A location watch didn't have an error handler!");
      });
    }
  }

  // @override
  public clearWatch(watchId: number): void {
    this.watches.delete(watchId);
  }

  // @override
  public getCurrentPosition(
    successCallback: PositionCallback,
    _errorCallback?: PositionErrorCallback | null,
    _options?: PositionOptions,
  ): void {
    // Don't really intend to use the 'getCurrentPosition' on this implementation, but we do this for
    // completeness
    successCallback(DEFAULT_POSITION);
  }

  // @override
  public watchPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback | null,
    _options?: PositionOptions,
  ): number {
    const currentWatchId = this.nextWatchId;
    this.watches.set(currentWatchId, {
      successCallback,
      errorCallback,
    });
    this.nextWatchId++;

    this.initialisePositionReplay();
    return currentWatchId;
  }
}
