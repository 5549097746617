import classNames from "classnames";
import { FC, Fragment, useState } from "react";

import styles from "./AssetDescription.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  dataQA: string;
  className?: string;
  content: string;
}

interface TextRendererProps {
  text: string;
}

const MAX_CHARS = 187;

const TextRenderer: FC<TextRendererProps> = ({ text }) => {
  // Split text by double newlines to separate paragraphs
  const paragraphs = text.split("\n\n");

  return (
    <div>
      {paragraphs.map((paragraph, idx) => (
        <p key={idx}>
          {/* Split by single newline to create <br /> tags */}
          {paragraph.split("\n").map((line, lineIdx) => (
            <Fragment key={lineIdx}>
              {line}
              {/* Avoid <br /> after last line */}
              {lineIdx !== paragraph.split("\n").length - 1 && <br />}
            </Fragment>
          ))}
        </p>
      ))}
    </div>
  );
};

const AssetDescription = ({ content, dataQA, className }: Props) => {
  const overlimit = content.length > MAX_CHARS;
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <span
        data-qa={dataQA}
        className={classNames(styles.container, className)}
      >
        <TextRenderer
          text={
            !expanded && overlimit
              ? `${content.slice(0, MAX_CHARS)}.... `
              : content
          }
        />
      </span>
      {!expanded && overlimit && (
        <span
          className={styles.readMore}
          onClick={() => {
            setExpanded(true);
          }}
        >
          {t("poi.read_more_button")}
        </span>
      )}
    </>
  );
};

export default AssetDescription;
