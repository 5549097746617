import { useAppSelector } from "../redux/hooks";
import { Bookmark, MappedBookmark } from "../redux/services/types";
import { parseLanguageObject } from "../utils/parseLanguageObject";

export const useBookmarkData = (
  rawBookmarks?: Bookmark[],
): { bookmarks: MappedBookmark[] } => {
  const { language } = useAppSelector((state) => state.application);

  const bookmarks =
    rawBookmarks?.map((bookmark) => ({
      ...bookmark,
      name: parseLanguageObject(bookmark.name, language) || "",
      floor: {
        ...bookmark.floor,
        name: parseLanguageObject(bookmark.floor.name, language) || "",
      },
    })) || [];

  return { bookmarks };
};
