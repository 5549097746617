import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import SwiperClass from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import RouteStepBanner, {
  RouteStepBannerProps,
} from "../RouteStepBanner/RouteStepBanner";

import styles from "./RouteStepSlider.module.scss";
import classNames from "classnames";

export interface SwiperRef extends React.HTMLAttributes<HTMLElement> {
  swiper: SwiperClass;
}

interface Props {
  className?: string;
  dataQA: string;
  slides?: Omit<RouteStepBannerProps, "dataQA">[];
  onChange: (swiper: SwiperClass) => void;
  onClick: (
    swiper: SwiperClass,
    event: MouseEvent | TouchEvent | PointerEvent,
  ) => void;
  sliderRef: React.RefObject<SwiperRef>;
  showTime?: boolean;
}

const RouteStepSlider = ({
  className,
  dataQA,
  slides,
  onChange,
  onClick,
  sliderRef,
  showTime,
}: Props) => {
  return slides ? (
    <Swiper
      data-qa={dataQA}
      ref={sliderRef}
      modules={[Pagination]}
      className={classNames(styles.container, className)}
      pagination={{
        dynamicBullets: true,
        dynamicMainBullets: 8,
        bulletClass: `${styles.bullet} swiper-pagination-bullet`,
        horizontalClass: styles.bullets,
      }}
      spaceBetween={30}
      onSlideChange={onChange}
      onClick={onClick}
    >
      {slides.map(({ text, accent, icon, time, subText }, index) => (
        <SwiperSlide className={styles.slide} key={index}>
          <RouteStepBanner
            size="medium"
            className={styles.step}
            dataQA={"route-step-banner-slide"}
            text={text}
            subText={subText}
            icon={icon}
            time={showTime ? time : undefined}
            accent={accent}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  ) : null;
};

export default RouteStepSlider;
