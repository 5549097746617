import { LanguageObject } from "../redux/services/types";
import { FALLBACK_LANGUAGE } from "../constants";

export const parseLanguageObject = (
  languageObject: LanguageObject[],
  language: string,
): string | null =>
  (
    languageObject.find((item) => item.lang === language) ||
    languageObject.find((item) => item.lang === FALLBACK_LANGUAGE)
  )?.text || null;
