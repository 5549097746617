import { routingPaths } from "./types";

export const buildRoutingPath = (
  path: routingPaths,
  params: {
    fromName?: string;
    fromId?: string | number;
    toName?: string;
    toId?: string | number;
  },
) => {
  const { fromName, fromId, toName, toId } = params;

  return `/${path}/${encodeURIComponent(fromName ? fromName : "-")}/${
    fromId || `-`
  }/${encodeURIComponent(toName ? toName : "-")}/${toId || `-`}/`;
};
