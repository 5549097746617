import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { BottomSheetRef } from "react-spring-bottom-sheet";
import { Sheet } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  clearLocation,
  storeLocation,
} from "../../redux/slices/applicationSlice";

import useSearchResults from "./hooks/useSearchResults";
import useResponsive from "../../hooks/useResponsive";
import { useRecentSearches } from "../../hooks/useRecentSearches";
import { useSearchBarProps } from "../../hooks/useSearchBarProps";
import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";

import createLMFeature from "../../utils/createLMFeature";
import { parseLanguageObject } from "../../utils/parseLanguageObject";
import { AnalyticsEvent } from "../../utils/analyticsTypes";
import { push } from "../../utils/navigate";
import { Path, QueryParam, QueryParams } from "../../utils/types";

import { MapContext } from "../../contexts/MapContext";

import SearchResultList from "./components/SearchResultList";
import LoadingChip from "./components/LoadingChip";
import SearchBar from "../../components/SearchBar/SearchBar";

import styles from "./SearchResultsView.module.scss";

const SearchResultsView: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { Default, Mobile } = useResponsive();

  const {
    chipContainerRef,
    clusteredPinControl,
    interactionControl,
    floorControl,
    mapCentre,
  } = useContext(MapContext);

  const { language, queryParamsConfig } = useAppSelector(
    (state) => state.application,
  );

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  const { featureData, isFetching } = useSearchResults({
    activeFloor: floorControl?.getActiveFloor(),
    mapCentre,
  });

  const { recentSearches } = useRecentSearches({});

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const sheetRef = useRef<BottomSheetRef | null>(null);
  const firstSearchResultRef = useRef<HTMLDivElement>(null);

  const [sheetIsScrollable, setSheetIsScrollable] = useState(false);

  const searchBarProps = useSearchBarProps({
    ref: searchInputRef,
    className: styles.searchbar,
    onClose: () =>
      sheetRef?.current?.snapTo(({ headerHeight }) => headerHeight),
    onSubmit: () =>
      setTimeout(() => {
        sheetRef.current?.snapTo(({ snapPoints }) =>
          snapPoints?.length === 3 ? snapPoints[1] : snapPoints[0],
        );
      }),
  });

  const handleClickSearchResult = useCallback(
    (id: string, name: string) => {
      logAnalyticsEvent({
        event_type: AnalyticsEvent.SEARCH_FEATURE_OPEN,
        event_data: { lm_id: id, name },
      });

      dispatch(
        push({
          pathOrLocation: `/${Path.FEATURE}`,
          newQueryParams: { [QueryParams.FEATURE_ID]: id },
          state: { ...location.state, search: true },
          discardParams: [
            QueryParams.QUERY,
            QueryParams.QUICK_SEARCH,
            QueryParam.NAME_SEARCH,
          ],
        }),
      );
    },
    [dispatch, logAnalyticsEvent, location.state],
  );

  const SearchResultListComponent = () => (
    <SearchResultList
      data={featureData}
      onClickSearchResult={handleClickSearchResult}
      firstSearchResultRef={firstSearchResultRef}
      isFetching={isFetching}
    />
  );

  const sheetElement = document.querySelector(
    `.${styles.mobileSearchSheet} [aria-modal]`,
  );

  useEffect(() => {
    if (!sheetElement) return; // if sheetElement is not available, do nothing

    const resizeObserver = new ResizeObserver(() => {
      window.innerHeight - 20 === sheetElement.clientHeight
        ? setSheetIsScrollable(true)
        : setSheetIsScrollable(false);
    });

    resizeObserver.observe(sheetElement);

    return () => resizeObserver.disconnect();
  }, [sheetElement]);

  useEffect(() => {
    // we don't want to store the location if there's only 1 search result returned because we navigate straight to the FIP
    // which will cause the FIP to not close when clicking on the x icon
    if (featureData && featureData.length > 1)
      dispatch(storeLocation(location));
    else dispatch(clearLocation());

    clusteredPinControl?.clearClusteredPinSource();
    interactionControl?.deselectFeatures();

    if (featureData) {
      setTimeout(() => {
        sheetRef.current?.snapTo(({ snapPoints }) =>
          snapPoints?.length === 3 ? snapPoints[1] : snapPoints[0],
        );
      }, 100);

      if (featureData.length) {
        const lmFeatures = featureData.map((feature) => {
          return createLMFeature(feature, language);
        });

        clusteredPinControl?.updateClusteredPins(lmFeatures);

        const sheetHeight = sheetRef.current?.height;
        clusteredPinControl?.zoomToClusteredPins(lmFeatures, {
          bottom: sheetHeight ? sheetHeight + 20 : 30,
          top: 30,
          left: 30,
          right: 30,
        });
      }
    }
  }, [
    clusteredPinControl,
    dispatch,
    featureData,
    interactionControl,
    language,
    location,
    sheetRef,
  ]);

  useEffect(() => {
    // when there's only 1 search result we will navigate straight to the FIP
    if (featureData?.length === 1) {
      const { id, information } = featureData[0];
      const name = parseLanguageObject(information.long_name, language) || "";

      handleClickSearchResult(id, name);
    }
  }, [featureData, handleClickSearchResult, language]);

  return (
    queryParamsConfig["ui-controls"] !== "hide" && (
      <>
        {Default && <SearchResultListComponent />}
        {Mobile && (
          <Sheet
            scrollLocking={false}
            dataQA="mobile-search-sheet"
            open
            ref={sheetRef}
            className={classNames([styles.mobileSearchSheet], {
              [styles.scrollSheet]: sheetIsScrollable,
            })}
            snapPoints={({ maxHeight, headerHeight }) => {
              const firstSearchResultHeight = firstSearchResultRef.current
                ?.clientHeight
                ? firstSearchResultRef.current?.clientHeight + 20 // 20 is the padding of the search result
                : 0;

              return [
                !featureData?.length
                  ? headerHeight + firstSearchResultHeight
                  : headerHeight - 20,
                headerHeight + firstSearchResultHeight,
                maxHeight - 20,
              ];
            }}
            onSpringStart={(event) => {
              window.scrollTo(0, 0);

              if (event.type === "SNAP" && event.source === "dragging") {
                searchInputRef.current?.blur(); // accessed from the child component
              }
            }}
            onSpringEnd={() => {
              window.scrollTo(0, 0);
            }}
            defaultSnap={({ snapPoints }) =>
              snapPoints?.length === 3 ? snapPoints[1] : snapPoints[0]
            }
            skipInitialTransition
            header={
              <>
                <SearchBar
                  {...searchBarProps}
                  dataQA="mobile-search"
                  flatInput
                  align="center"
                  cancelButton
                  compact
                  roundedClearIcon
                  onFocus={() => {
                    setTimeout(() => {
                      sheetRef.current?.snapTo(({ maxHeight }) => maxHeight);
                    }, 100);
                  }}
                />
                {!isFetching && !featureData?.length ? null : (
                  <div className={styles.searchResultsHeader}>
                    <div className={styles.results}>
                      {isFetching ? (
                        <div
                          className={classNames(
                            styles.loadingResults,
                            styles.skeletonBox,
                          )}
                        />
                      ) : (
                        `${featureData?.length} results`
                      )}
                    </div>
                  </div>
                )}
              </>
            }
          >
            {location.pathname === "/" &&
              recentSearches.length > 0 &&
              recentSearches}
            <SearchResultListComponent />
            {location.pathname === "/" && !recentSearches.length && (
              <div style={{ padding: 10 }}></div>
            )}
          </Sheet>
        )}
        {isFetching && (
          <LoadingChip
            chipContainerRef={chipContainerRef}
            isFetching={isFetching}
          />
        )}
      </>
    )
  );
};

export default SearchResultsView;
