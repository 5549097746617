import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useQuickSearchTags } from "./useQuickSearchTags";
import { useFeatureSearchParams } from "./useFeatureSearchParams";

/**
 * Hook to get the search query from the URL and then set it depending if its a quick search or not
 */
export const useSearchQuery = () => {
  const location = useLocation();

  const { isQuickSearch, query } = useFeatureSearchParams();

  const { getQuickSearchTagFromId } = useQuickSearchTags();

  const [searchQuery, setSearchQuery] = useState("");

  const clearSearchQuery = () => setSearchQuery("");

  // Set search query on query param change
  useEffect(() => {
    if (query)
      setSearchQuery(isQuickSearch ? getQuickSearchTagFromId(query) : query);
  }, [query, isQuickSearch, getQuickSearchTagFromId]);

  useEffect(() => {
    if (location.pathname === "/") clearSearchQuery();
  }, [location]);

  return { searchQuery, clearSearchQuery };
};
