import LivingMap, { LivingMapPlugin } from "@livingmap/core-mapping";
import { MapMouseEvent } from "../../../../node_modules/@types/mapbox-gl";

export default class DebugControl extends LivingMapPlugin {
  constructor(id: string, LMMap: LivingMap) {
    super(id, LMMap);
    this.LMMap = LMMap;
  }

  activate(): void {}

  deactivate(): void {}

  refresh(): void {
    return;
  }

  public enableDebug() {
    this.LMMap.getMapboxMap().on("click", this.logMapCoordinate);
    this.addMapboxDebugVisuals(true);
  }

  public disableDebug() {
    this.LMMap.getMapboxMap().off("click", this.logMapCoordinate);
    this.addMapboxDebugVisuals(false);
  }

  /**
   * Function will add some basic mapbox debug visuals on top of the map. It will:
   * - Visualise collionBoxes on Map elements
   * - Visualise Tile boundaries
   * @private
   */
  private addMapboxDebugVisuals(state: boolean): void {
    const mapInstance = this.LMMap.getMapboxMap();
    mapInstance.showCollisionBoxes = state;
    mapInstance.showTileBoundaries = state;
  }

  /**
   * Given a click on the map, this function will log the clicked coordinates to the browser console
   * @private
   * @param e
   */
  private logMapCoordinate(e: MapMouseEvent): void {
    console.log("LngLat: ", e.lngLat.toArray());
    console.log("Point: ", e.point);
  }
}
