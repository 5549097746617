import { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Icon, IconButton, Sheet } from "@livingmap/core-ui-v2";

import {
  clearLocation,
  storeLocation,
} from "../../redux/slices/applicationSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { usePostRouteData } from "../RouteView/hooks/usePostRouteData";
import { useDisplayRoute } from "../../hooks/useDisplayRoute";
import { useRedirectToDesktopRoute } from "../../hooks/useRedirectToDesktopRoute";
import { useRoutingUserActiveFloor } from "../../hooks/useRoutingUserActiveFloor";

import TimeToDestination from "../../components/TimeToDestination/TimeToDestination";
import RouteContent from "../RouteView/components/RouteContent/RouteContent";

import { QueryParams, RoutingPath } from "../../utils/types";
import { push, replace } from "../../utils/navigate";
import { buildRoutingPath } from "../../utils/buildRoutingPath";
import { showRouteTime } from "../../utils/showRouteTime";

import styles from "./JourneyOverviewView.module.scss";
import { MapContext } from "../../contexts/MapContext";
import { useGetMapConfigQuery } from "../../redux/services/vectormapsAPI";
import { useTranslation } from "react-i18next";

const WARNING_MESSAGE_HEIGHT = 64;
const MILESTONE_HEIGHT = 73;

export default function JourneyOverviewView() {
  const { floorControl, routingControl } = useContext(MapContext);
  const { t } = useTranslation();

  useRedirectToDesktopRoute();
  useRoutingUserActiveFloor({
    getActiveFloor: () => floorControl?.getActiveFloor(),
  });

  const dispatch = useAppDispatch();
  const { fromName, fromId, toName, toId } = useParams();
  const location = useLocation();

  const { savedLocation, routingUserActiveFloor } = useAppSelector(
    (state) => state.application,
  );

  const { data: mapData } = useGetMapConfigQuery();

  const { data: routeData, isError } = usePostRouteData({
    fromId,
    toId,
    floorId: routingUserActiveFloor?.id,
  });

  const { routeMilestones } = useDisplayRoute({
    routingControl,
    segments: routeData?.segments,
    sequenceOrder: routeData?.routeMetadata[0].sequenceOrder,
  });

  const showTime =
    routeData &&
    mapData &&
    showRouteTime(
      mapData.routing?.time_to_destination_enabled,
      mapData.routing?.time_to_destination_limit,
      routeData.routeMetadata[0].totalTime,
    );

  useEffect(() => {
    dispatch(storeLocation(location));
  }, [dispatch, location]);

  useEffect(() => {
    if (isError)
      dispatch(
        replace({
          pathOrLocation: "/",
          discardParams: [QueryParams.STEP_FREE, QueryParams.MILESTONE_INDEX],
        }),
      );
  }, [dispatch, isError, savedLocation]);

  return routeData && mapData ? (
    <>
      <TimeToDestination
        skipGo
        dataQA={"journey-time-to-destination"}
        className={styles.timeToDestination}
        currentStep={0}
        kilometres={routeData.routeMetadata[0].totalLength}
        displayUnit={mapData.routing?.display_unit_system || "metric"}
        minutes={routeData.routeMetadata[0].totalTime}
        live={fromId?.includes(",") || toId?.includes(",")}
        steps={routeMilestones.length}
        showTime={showTime}
        onClick={() => {
          dispatch(clearLocation());
          routingControl!.setJourneyMode(false);
          dispatch(
            replace({
              pathOrLocation: "/",
              discardParams: [
                QueryParams.STEP_FREE,
                QueryParams.MILESTONE_INDEX,
              ],
            }),
          );
        }}
      />
      <Sheet
        scrollLocking={false}
        dataQA="journey-overview-view-sheet"
        snapPoints={({ maxHeight, headerHeight }) => [
          headerHeight,
          headerHeight + WARNING_MESSAGE_HEIGHT + MILESTONE_HEIGHT + 20, // 20px margin
          maxHeight - 20,
        ]}
        open
        defaultSnap={({ headerHeight }) => headerHeight}
        header={
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <Icon
                height={19}
                type="NearMeTopIcon"
                dataQA="near-me-top-icon"
              />
              <p className={styles.title}>{t("journey_overview.heading")}</p>
            </div>
            <IconButton
              className={styles.closeButton}
              icon="CloseIcon"
              dataQA="close-route-menu-button"
              onClick={() => {
                dispatch(clearLocation());

                dispatch(
                  push({
                    pathOrLocation: buildRoutingPath(RoutingPath.JOURNEY, {
                      fromName,
                      fromId,
                      toName,
                      toId,
                    }),
                    ...(!savedLocation && {
                      discardParams: [
                        QueryParams.STEP_FREE,
                        QueryParams.MILESTONE_INDEX,
                      ],
                    }),
                  }),
                );
              }}
            />
          </div>
        }
      >
        <RouteContent
          routeMilestones={routeMilestones}
          totalTime={routeData?.routeMetadata[0].totalTime || 0}
          showTime={showTime}
        />
      </Sheet>
    </>
  ) : null;
}
