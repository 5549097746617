import { Sheet, IconButton } from "@livingmap/core-ui-v2";
import { useParams, useLocation } from "react-router-dom";

import RouteInput from "../../../../components/RouteInput/RouteInput";
import useGetRouteInputData from "../../../../hooks/useGetRouteInputData";
import useResponsive from "../../../../hooks/useResponsive";
import { useAppDispatch } from "../../../../redux/hooks";
import { useGetMapConfigQuery } from "../../../../redux/services/vectormapsAPI";
import {
  clearLocation,
  storeLocation,
} from "../../../../redux/slices/applicationSlice";
import { buildRoutingPath } from "../../../../utils/buildRoutingPath";
import { push } from "../../../../utils/navigate";
import { QueryParams, RoutingPath } from "../../../../utils/types";

import styles from "./MobileRouteView.module.scss";
import { useTranslation } from "react-i18next";

const TIME_TO_DESTINATION_HEIGHT = 77;
const STEP_FREE_TOGGLE_HEIGHT = 46;

interface Props {
  onSwapButtonClick: () => void;
  isFetchingRoute?: boolean;
  stepFreeToggleComponent: JSX.Element;
  timeToDestinationComponent: JSX.Element | null;
  isRouteSuccess?: boolean;
  children: JSX.Element;
}

export default function MobileRouteView({
  onSwapButtonClick,
  isFetchingRoute,
  stepFreeToggleComponent,
  timeToDestinationComponent,
  isRouteSuccess,
  children,
}: Props) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { Mobile } = useResponsive();
  const { fromName, fromId, toName, toId } = useParams();
  const { t } = useTranslation();

  const { data: mapData } = useGetMapConfigQuery();

  const { getOriginRouteData, getDesintationRouteData } = useGetRouteInputData({
    origin: fromName,
    destination: toName,
  });

  const handleChoosePoi = (selectDestination: boolean) => {
    dispatch(storeLocation(location));

    dispatch(
      push({
        pathOrLocation: buildRoutingPath(RoutingPath.ROUTE, {
          fromName,
          fromId,
          toName,
          toId,
        }),
        state: { selectDestination },
      }),
    );
  };

  return (
    <>
      {Mobile && (
        <Sheet
          scrollLocking={false}
          dataQA="route-view-sheet"
          snapPoints={({ maxHeight, headerHeight }) => [
            headerHeight,
            ...(!isFetchingRoute
              ? [
                  headerHeight -
                    TIME_TO_DESTINATION_HEIGHT -
                    (mapData?.routing?.step_free_enabled
                      ? STEP_FREE_TOGGLE_HEIGHT
                      : 0),
                ]
              : []),
            ...(!isFetchingRoute ? [maxHeight - 20] : []),
          ]}
          open
          defaultSnap={({ headerHeight }) => headerHeight}
          header={
            <div className={styles.header}>
              <div className={styles.routeInputContainer}>
                <RouteInput
                  readonly
                  dataQA="route-input"
                  origin={getOriginRouteData()}
                  destination={getDesintationRouteData()}
                  onChooseDestination={() => handleChoosePoi(true)}
                  onChooseOrigin={() => handleChoosePoi(false)}
                  onSwapButtonClick={onSwapButtonClick}
                  isDisabled={isFetchingRoute}
                />
                <IconButton
                  className={styles.closeButton}
                  icon="CloseIcon"
                  dataQA="close-route-menu-button"
                  onClick={() => {
                    dispatch(clearLocation());

                    dispatch(
                      push({
                        pathOrLocation: "/",
                        discardParams: [QueryParams.STEP_FREE],
                      }),
                    );
                  }}
                />
              </div>
              {mapData?.routing?.step_free_enabled && (
                <div className={styles.stepFreeToggleContainer}>
                  {stepFreeToggleComponent}
                </div>
              )}
              <div className={styles.routeSummaryContainer}>
                {isFetchingRoute ? (
                  <div className={styles.textSkeleton} />
                ) : (
                  timeToDestinationComponent
                )}
                {!isRouteSuccess && !isFetchingRoute && (
                  <div className={styles.routeError}>
                    {t("navigate_page.no_results")}
                  </div>
                )}
              </div>
            </div>
          }
        >
          {isRouteSuccess ? children : <div className={styles.emptyContent} />}
        </Sheet>
      )}
    </>
  );
}
