export const showRouteTime = (
  enabled: boolean = true,
  limit: number | null = null,
  minutes: number,
) => {
  if (!enabled) return false;

  if (!limit) return true;

  return minutes < limit;
};
