import { Point, Polygon, Feature } from "geojson";
import { GeoJSONSource, Map } from "mapbox-gl";
import { createGeoJSONFeature } from "@livingmap/core-mapping/dist/utils";

export interface PositionProperties {
  heading: number;
  accuracy: number;
  floor_id?: number;
}

const KM_PER_DEGREE_LONGITUDE = 111.32;
const KM_PER_DEGREE_LATITUDE = 110.574;

export default class PositionAnimator {
  private mapbox: Map;
  private lastPoint: Point | undefined;
  private counter = 0;
  private points: Point[] = [];
  private properties: PositionProperties | undefined;
  private initialAccuracy = 0;
  private targetAccuracy = 0;

  constructor(mapbox: Map) {
    this.mapbox = mapbox;
  }

  public addPosition(position: Point, properties: PositionProperties): void {
    this.points = [];
    this.initialAccuracy = this.properties?.accuracy || 0;
    this.targetAccuracy = properties.accuracy;
    this.properties = properties;

    if (this.lastPoint) {
      const [nx, ny] = position.coordinates;
      const [lx, ly] = this.lastPoint.coordinates;

      const diffX = nx - lx;
      const diffY = ny - ly;
      const pointNum = 30;

      const intervalX = diffX / (pointNum + 1);
      const intervalY = diffY / (pointNum + 1);

      for (let i = 0; i < pointNum; i++) {
        const x = Number((lx + intervalX * i).toFixed(7));
        const y = Number((ly + intervalY * i).toFixed(7));
        this.points.push({ type: "Point", coordinates: [x, y] });
      }
    }

    this.points.push(position);
    this.lastPoint = position;
    this.counter = 0;

    this.animateMarker();
  }

  private animateMarker(): void {
    if (this.mapbox && this.counter < this.points.length) {
      const userLocationFeature = createGeoJSONFeature(
        this.properties!,
        this.points[this.counter],
      );

      const iconLayer = this.mapbox.getSource("user-location-layer-source");
      (iconLayer as GeoJSONSource).setData(userLocationFeature);

      const progress = this.counter / (this.points.length - 1);
      const easedProgress = 1 - Math.pow(1 - progress, 3);
      const interpolatedAccuracy =
        this.initialAccuracy +
        (this.targetAccuracy - this.initialAccuracy) * easedProgress;

      const polygonLayer = this.mapbox.getSource(
        "user-location-accuracy-source",
      );
      (polygonLayer as GeoJSONSource).setData(
        this.createGeoJSONCircle(
          this.points[this.counter],
          interpolatedAccuracy,
        ),
      );

      this.counter += 1;
      requestAnimationFrame(() => this.animateMarker());
    }
  }

  private createGeoJSONCircle(point: Point, radius: number): Feature<Polygon> {
    const points = 64;

    const [longitude, latitude] = point.coordinates;
    const centre = { longitude, latitude };

    const circleCoordinates = [];
    const lonDegreeFactorAtLat = Math.cos((centre.latitude * Math.PI) / 180);
    const distanceX = radius / (KM_PER_DEGREE_LONGITUDE * lonDegreeFactorAtLat);
    const distanceY = radius / KM_PER_DEGREE_LATITUDE;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      circleCoordinates.push([centre.longitude + x, centre.latitude + y]);
    }

    circleCoordinates.push(circleCoordinates[0]);

    return {
      type: "Feature",
      geometry: { type: "Polygon", coordinates: [circleCoordinates] },
      properties: {},
    };
  }
}
