import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";

import { OperatingHours } from "../../redux/services/types";

import { formatTime } from "../../hooks/formatTime";
import { useCalculateOperatingHours } from "../../hooks/useCalculateOperatingHours";

import { Status } from "./types";

import MetaData from "../MetaData/MetaData";
import OpenTimeSpan from "../OpenTimeSpan/OpenTimeSpan";

import styles from "./OpenTimeInfo.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  dataQA: string;
  isTemporarilyClosed: boolean;
  operatingHours: OperatingHours;
  className?: string;
}

const OpenTimeInfo = ({
  dataQA,
  isTemporarilyClosed,
  operatingHours,
  className,
}: Props) => {
  const localRef = useRef<HTMLTableElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [animatedHeight, animateHeight] = useSpring(() => ({}));
  const { t } = useTranslation();

  useEffect(() => {
    animateHeight.start({
      opacity: isOpen ? 1 : 0,
      height: isOpen ? `${localRef.current!.clientHeight + 10}px` : "0", // 10px to account for margin that is cropped off
    });
  }, [animateHeight, localRef, isOpen]);

  const { todaysDay, checkIsActiveOpeningTime } =
    useCalculateOperatingHours(operatingHours);

  return (
    <div data-qa={dataQA} className={classNames(styles.container, className)}>
      <MetaData icon="HoursIcon" dataQA="open-time-info">
        {isTemporarilyClosed ? (
          <OpenTimeSpan
            dataQA="open-time-span"
            operatingHours={operatingHours}
            isTemporarilyClosed={isTemporarilyClosed}
          />
        ) : (
          <div className={styles.infoContainer}>
            <button
              className={styles.title}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <OpenTimeSpan
                dataQA="open-time-span"
                operatingHours={operatingHours}
              />
              <Icon
                className={styles.chevron}
                type={isOpen ? "ChevronUpIcon" : "ChevronDownIcon"}
                dataQA="open-time-info-icon"
              />
            </button>
          </div>
        )}
      </MetaData>
      {!isTemporarilyClosed && operatingHours && (
        <animated.div
          style={{ overflow: "hidden", height: 0, ...animatedHeight }}
        >
          <table className={styles.daysInfo} ref={localRef}>
            <tbody>
              {Object.entries(operatingHours).map(
                ([day, dayOperatingHours]) => (
                  <tr key={day}>
                    <td
                      className={classNames({
                        [styles.active]: day === todaysDay,
                      })}
                    >
                      <span>{t(`operating_hours_day_${day}`)}</span>
                    </td>
                    <td>
                      {dayOperatingHours ? (
                        dayOperatingHours.map((hours, index) => (
                          <div
                            key={index}
                            className={classNames({
                              [styles.active]:
                                day === todaysDay &&
                                checkIsActiveOpeningTime(hours),
                            })}
                          >
                            {`${formatTime(hours.opening_time)} – ${formatTime(
                              hours.closing_time,
                            )}`}
                          </div>
                        ))
                      ) : (
                        <div
                          className={classNames({
                            [styles.active]: day === todaysDay,
                          })}
                        >
                          {t(`operating_hours_status_${Status.CLOSED}`)}
                        </div>
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </animated.div>
      )}
    </div>
  );
};

export default OpenTimeInfo;
