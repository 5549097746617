import { useState } from "react";
import classNames from "classnames";

import { useAppSelector } from "../redux/hooks";
import { useGetSearchFeatureNamesQuery } from "../redux/services/vectormapsAPI";

import { highlightSelectedText } from "../utils/highlightSelectedText";

import ListItemResult from "../components/ListItemResult/ListItemResult";
import { IconType } from "@livingmap/core-ui-v2";

interface Props {
  limit?: number;
  className?: string;
  highlightedIndex?: number;
  highlightedClassName?: string;
  icon?: IconType;
  handleOnClick: (value: string) => void;
}

export const useSearchSuggestions = ({
  limit,
  className,
  highlightedIndex,
  highlightedClassName,
  handleOnClick,
  icon,
}: Props) => {
  const { project, language } = useAppSelector((state) => state.application);

  const [searchSuggestion, setSearchSuggestion] = useState("");

  const { data: featureNamesList } = useGetSearchFeatureNamesQuery(
    { project, lang: language },
    { skip: !project },
  );

  const searchSuggestionItems = featureNamesList
    ? featureNamesList.data
        .filter(
          (str) =>
            str.toUpperCase().includes(searchSuggestion.toUpperCase()) &&
            searchSuggestion !== "",
        )
        .slice(0, limit || featureNamesList.data.length)
    : [];

  const searchSuggestionsList = searchSuggestionItems.map(
    (value: string, index: number) => {
      const isActive = highlightedIndex === index;
      const displayIcon = icon ? icon : "SearchIcon";
      return (
        <ListItemResult
          key={index}
          className={classNames(className, {
            [highlightedClassName!]: isActive,
          })}
          title={highlightSelectedText(searchSuggestion, value)}
          dataQA="search-suggestion-item"
          icon={displayIcon}
          onClick={() => {
            handleOnClick(value);
          }}
        />
      );
    },
  );

  return {
    searchSuggestion,
    setSearchSuggestion,
    searchSuggestionsList,
  };
};
