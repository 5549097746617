import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GitInfo from "react-git-info/macro";
import packageJSON from "../package.json";
import { useLocation, useSearchParams } from "react-router-dom";

import i18n from "./i18next";

import { useSessionId } from "./hooks/useSessionId";
import { useGetMapConfigQuery } from "./redux/services/vectormapsAPI";
import { useLazyGetFlightFullDetailsQuery } from "./redux/services/flightConnectorAPI";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setLanguage, setProject } from "./redux/slices/applicationSlice";
import { LanguageConfig } from "./redux/services/types";
import useQueryParamConfig from "./hooks/useQueryParamConfig";
import { useDeviceInfo } from "./hooks/useDeviceInfo";
import useMapOptionsConfig from "./hooks/useMapOptionsConfig";

import { parseLanguageObject } from "./utils/parseLanguageObject";
import { decodePlugin, handlePluginResponse } from "./utils/pluginSelect";
import { QueryParam } from "./utils/types";

import MapContainer from "./containers/MapContainer/MapContainer";

import { LoadingMapView } from "./views";
import { replace } from "./utils/navigate";

const { commit, branch } = GitInfo();

export default function App() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useSessionId();
  useDeviceInfo();

  const dispatch = useAppDispatch();

  const { language, project, sessionId, deviceInformation, queryParamsConfig } =
    useAppSelector((state) => state.application);

  const { data: mapData, isLoading } = useGetMapConfigQuery();

  useMapOptionsConfig();
  useQueryParamConfig({
    defaultFloor: mapData?.floors.find((floor) => floor.default),
  });

  const [getFlightDetails, flightDetails] = useLazyGetFlightFullDetailsQuery();
  const pluginParam = queryParamsConfig["plugin"];

  useEffect(() => {
    if (pluginParam) {
      const pluginRequest = decodePlugin(pluginParam);
      getFlightDetails(pluginRequest);
    }
  }, [pluginParam, getFlightDetails]);

  useEffect(() => {
    handlePluginResponse(flightDetails.data, dispatch);
  }, [flightDetails.isSuccess, flightDetails.data, dispatch]);

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const getAppLanguage = useCallback(
    (defaultLanguage: LanguageConfig, availableLanguages: LanguageConfig[]) => {
      const langQueryParam = searchParams.get(QueryParam.LANG);

      if (
        langQueryParam &&
        availableLanguages.find(({ id }) => id === langQueryParam)
      )
        return langQueryParam;

      const browserLanguage = navigator.languages.find((lang) =>
        availableLanguages.find(({ id }) => id === lang),
      );

      return browserLanguage || defaultLanguage.id;
    },
    [searchParams],
  );

  // This useEffect is used to log the version, branch and commit hash of the app on load.
  useEffect(() => {
    console.log(
      `Version: ${packageJSON.version}\nBranch: ${branch}\nCommit hash: ${commit.hash}`,
    );
  }, []);

  // Show loading splash screen for minimum of 1 second
  useEffect(() => {
    if (!showSplashScreen) return;

    setTimeout(() => {
      setShowSplashScreen(false);
    }, 1000);
  }, [showSplashScreen]);

  useEffect(() => {
    if (mapData) {
      const appLanguage = getAppLanguage(
        mapData.languages.default,
        mapData.languages.available,
      );

      dispatch(setProject(mapData.id));
      dispatch(setLanguage(appLanguage));
      i18n.changeLanguage(appLanguage);

      dispatch(
        replace({
          pathOrLocation: location.pathname,
          newQueryParams: { lang: appLanguage },
        }),
      );
    }
  }, [dispatch, getAppLanguage, location.pathname, mapData]);

  useEffect(() => {
    if (!deviceInformation.os || !deviceInformation.osVersion) return;
    if (
      (deviceInformation.os === "iOS" &&
        parseFloat(deviceInformation.osVersion) <= 13) ||
      (deviceInformation.os === "Android" &&
        parseFloat(deviceInformation.osVersion) <= 9)
    ) {
      alert(
        "You are using an unsupported OS version and may experience issues. Please update your OS for optimal performance.",
      );
    }
  }, [deviceInformation.os, deviceInformation.osVersion]);

  if (isLoading || !mapData || showSplashScreen || !project || !sessionId)
    return <LoadingMapView />;

  return (
    <>
      <Helmet>
        <title>
          {parseLanguageObject(mapData.title, language) || "Living Map"}
        </title>
        <link rel="icon" href={mapData.favicon_url} />
      </Helmet>
      <MapContainer />
    </>
  );
}
