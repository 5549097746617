import LivingMap, { LivingMapPlugin } from "@livingmap/core-mapping";

import { Region, MappedFloor } from "../../../redux/services/types";

import {
  feature,
  point,
  featureCollection,
  bbox,
  bboxPolygon,
  intersect,
} from "@turf/turf";

class RegionPlugin extends LivingMapPlugin {
  private regions: Region[];
  private currentFloors: MappedFloor[];
  private allFloors: MappedFloor[];
  private maxZoom: number;

  public constructor(
    id: string,
    LMMap: LivingMap,
    regions: Region[],
    floors: MappedFloor[],
  ) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.regions = regions;
    this.allFloors = floors;
    this.currentFloors = [];
    this.maxZoom = 16;
  }

  activate(): void {
    this.updateAvailableFloors();
  }

  public updateAvailableFloors = () => {
    const mapActiveBounds = this.LMMap.getMapBounds();
    const visibleMapFeatureCollection = featureCollection([
      point(mapActiveBounds!.getNorthEast().toArray()),
      point(mapActiveBounds!.getSouthWest().toArray()),
    ]);
    const currentZoom = this.LMMap.getMapZoomLevel();
    const visibleBoundingBoxPolygon = bboxPolygon(
      bbox(visibleMapFeatureCollection),
    );
    this.currentFloors = [];

    if (currentZoom && currentZoom < this.maxZoom) {
      return this.currentFloors;
    }

    this.regions.forEach((region) => {
      const intersectionPolygon = intersect(
        visibleBoundingBoxPolygon,
        feature(region.geometry),
      );

      if (intersectionPolygon) {
        this.currentFloors = Array.from(
          [...this.currentFloors, ...region.floors]
            .reduce((m, o) => m.set(o.id, o), new Map())
            .values(),
        );
      }
    });
    this.currentFloors.sort(
      (a, b) => parseFloat(b.floor) - parseFloat(a.floor),
    );
  };

  public getAvailableFloors(): MappedFloor[] {
    if (this.currentFloors.length > 0) {
      return this.currentFloors;
    } else {
      if (this.regions.length > 0) {
        return [];
      } else {
        return this.allFloors;
      }
    }
  }
}

export default RegionPlugin;
