export class Queue<T> {
  private items: T[];
  private maxLength: number;

  constructor(maxLength: number) {
    this.items = [];
    this.maxLength = maxLength;
  }

  insert(item: T) {
    if (this.items.length >= this.maxLength) {
      this.items.shift();
    }

    this.items.push(item);
  }

  fetch() {
    return this.items;
  }

  clear() {
    this.items = [];
  }

  get length() {
    return this.items.length;
  }

  get first() {
    return this.items[0] || null;
  }

  get last() {
    return this.items[this.items.length - 1] || null;
  }
}
