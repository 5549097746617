import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

import { vectorMapsAPI } from "./services/vectormapsAPI";
import applicationSlice from "./slices/applicationSlice";
import transportAPI from "./services/transportAPI";
import flightConnectorAPI from "./services/flightConnectorAPI";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: {
    application: applicationSlice,
    router: routerReducer,
    [vectorMapsAPI.reducerPath]: vectorMapsAPI.reducer,
    [transportAPI.reducerPath]: transportAPI.reducer,
    [flightConnectorAPI.reducerPath]: flightConnectorAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      vectorMapsAPI.middleware,
      transportAPI.middleware,
      flightConnectorAPI.middleware,
      routerMiddleware,
    ]),
});

setupListeners(store.dispatch);

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
