import { FloatingIconButton, IconType } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import styles from "./LocationButton.module.scss";

export enum LocationStatus {
  INACTIVE = "inactive",
  SEARCHING = "searching",
  FOUND = "found",
  DISABLED = "disabled",
}

interface Props {
  dataQA: string;
  className?: string;
  status: LocationStatus;
  theme?: "light" | "dark";
  size?: "small" | "medium" | "large";
  onClick: () => void;
}

const LocationButton: React.FC<Props> = ({
  dataQA,
  className,
  status,
  theme = "light",
  size = "small",
  onClick,
}) => {
  let iconType: IconType = "NearMeOutlineIcon";

  switch (status) {
    case LocationStatus.FOUND:
      iconType = "NearMeIcon";
      break;
    case LocationStatus.DISABLED:
      iconType = "NearMeDisabledOutlineIcon";
      break;
    default:
      iconType = "NearMeOutlineIcon";
  }

  return (
    <FloatingIconButton
      dataQA={dataQA}
      icon={iconType}
      onClick={onClick}
      rounded
      theme={theme}
      size={size}
      className={classNames(styles.container, styles[theme], className, {
        [styles.found]: status === LocationStatus.FOUND,
        [styles.searching]: status === LocationStatus.SEARCHING,
      })}
    />
  );
};

export default LocationButton;
