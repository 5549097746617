import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "./../redux/hooks";
import { useAppSelector } from "../redux/hooks";
import { usePostSessionMutation } from "../redux/services/vectormapsAPI";
import { setSessionId } from "../redux/slices/applicationSlice";

export const useSessionId = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { project } = useAppSelector((state) => state.application);

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const [postSession] = usePostSessionMutation();

  const handlePostSession = useCallback(async () => {
    if (project) {
      try {
        const { uuid } = await postSession({ project }).unwrap();
        dispatch(setSessionId(uuid));
      } catch (e) {
        console.error(e);
      }
    }
  }, [dispatch, postSession, project]);

  useEffect(() => {
    handlePostSession();
  }, [handlePostSession]);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    // posts a new session after 15 minutes of inactivity
    timeout.current = setTimeout(() => handlePostSession(), 1000 * 60 * 15);
  }, [handlePostSession, location]);
};
