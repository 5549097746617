import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { TransportFeed } from "./types";
import { ENVIRONMENT } from "../../constants";

export const transportAPI = createApi({
  reducerPath: "transportAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://transport-api.${ENVIRONMENT}.livingmap.com/v3`,
  }),
  endpoints: (builder) => ({
    getTransportFeedsByTransportString: builder.query<
      TransportFeed[],
      string | null | undefined
    >({
      query: (transportString) => `/${transportString}`,
    }),
  }),
});

export const { useGetTransportFeedsByTransportStringQuery } = transportAPI;

export default transportAPI;
