import classNames from "classnames";
import { FC } from "react";

import styles from "./FeatureImage.module.scss";
import { Button, Icon } from "@livingmap/core-ui-v2";
import { useTranslation } from "react-i18next";

interface Props {
  url: string;
  dataQA: string;
  className?: string;
  alt: string;
  hasPanoramic?: boolean;
  onPanoramicClick?: () => void;
}

const FeatureImage: FC<Props> = ({
  url,
  dataQA,
  className,
  alt,
  hasPanoramic = false,
  onPanoramicClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.container, className)}>
      <img src={url} data-qa={dataQA} className={styles.image} alt={alt} />
      {hasPanoramic && (
        <Button
          dataQA="panorama-button"
          className={styles.panoramaButton}
          onClick={() => onPanoramicClick && onPanoramicClick()}
          color="white"
          rounded
        >
          <Icon dataQA="look-around-icon" type="RotateIcon" />
          <span>{t("poi.panorama_button")}</span>
        </Button>
      )}
    </div>
  );
};

export default FeatureImage;
