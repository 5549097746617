import classNames from "classnames";
import { FC, ReactNode, useEffect, useState } from "react";

import styles from "./RailAirDeparturesListCard.module.scss";
import { format } from "date-fns";
import { Button } from "@livingmap/core-ui-v2";
import DestinationSearchBar from "./components/DestinationSearchBar/DestinationSearchBar";
import NoDepartures from "../NoDepartures/NoDepartures";

export type RailAirDeparture = {
  scheduledDepartureTime: string;
  expectedDepartureTime: string;
  destination: string;
  service: string;
  platform?: string;
};

interface Props {
  dataQA: string;
  className?: string;
  departures: RailAirDeparture[];
}

const generateExpectedDepartureTime = (
  scheduledDepartureTime: string,
  expectedDepartureTime: string,
): ReactNode => {
  const scheduled = new Date(scheduledDepartureTime);
  const expected = new Date(expectedDepartureTime);

  if (scheduled < expected) {
    return (
      <span className={styles.delayed}>
        Expected {format(expected, "HH:mm")}
      </span>
    );
  }

  return <span className={styles.onTime}>On time</span>;
};

const generateDepartureListItems = (
  departures: RailAirDeparture[],
  filter: string,
): ReactNode[] => {
  // Function to remove punctuation
  const sanitizeString = (str: string) => {
    return str.replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ");
  };

  const sanitizedFilter = sanitizeString(filter).trim().toLowerCase();

  return departures
    .filter((departure) => {
      if (sanitizedFilter === "") {
        return true;
      }
      const sanitizedDestination = sanitizeString(
        departure.destination,
      ).toLowerCase();
      return sanitizedDestination.includes(sanitizedFilter);
    })
    .map((departure, index) => {
      return (
        <li className={styles.departureListItem} key={index}>
          <span className={styles.departureTime}>
            {format(new Date(departure.scheduledDepartureTime), "HH:mm")}
          </span>
          <div className={styles.serviceDetails}>
            <div className={styles.destinationAndTime}>
              <span className={styles.departure}>{departure.destination}</span>
              <span className={styles.platform}>
                {departure.platform ? `Platform ${departure.platform}` : "—"}
              </span>
            </div>
            <div className={styles.operatorAndPlatform}>
              <span className={styles.operator}>{departure.service}</span>
              <span className={styles.time}>
                {generateExpectedDepartureTime(
                  departure.scheduledDepartureTime,
                  departure.expectedDepartureTime,
                )}
              </span>
            </div>
          </div>
        </li>
      );
    });
};

const RailAirDeparturesListCard: FC<Props> = ({
  dataQA,
  className,
  departures,
}) => {
  const [filter, setFilter] = useState("");
  const [displayLimit, setDisplayLimit] = useState(10); // initial limit set to 10

  // Reset displayLimit when filter changes
  useEffect(() => {
    setDisplayLimit(10);
  }, [filter]);

  const loadMoreDepartures = () => {
    setDisplayLimit((prevLimit) => prevLimit + 10); // load 10 more departures
  };

  // First, apply the filter, then slice the array to apply pagination
  const filteredDepartures = generateDepartureListItems(departures, filter);
  const visibleDepartures = filteredDepartures.slice(0, displayLimit);

  return (
    <div className={classNames(styles.container, className)}>
      <DestinationSearchBar
        dataQA="rail-air-destination-filter"
        value={filter}
        onChange={setFilter}
      />
      {visibleDepartures.length > 0 ? (
        <>
          <ul data-qa={dataQA} className={styles.departuresList}>
            {visibleDepartures}
          </ul>
          {filteredDepartures.length > displayLimit && ( // Show load more button only if there are more departures to load
            <Button
              dataQA="rail-air-load-more-departures"
              onClick={loadMoreDepartures}
              className={styles.loadMoreButton}
              outlined
              rounded
            >
              Show More
            </Button>
          )}
        </>
      ) : (
        <NoDepartures
          title="No departures"
          subtitle="Check your spelling or try another search."
        />
      )}
    </div>
  );
};

export default RailAirDeparturesListCard;
