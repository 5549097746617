export enum AnalyticsEvent {
  MAP_LOAD = "map_load",
  MAP_FEATURE_OPEN = "map_feature_open",
  MAP_LEVEL_CHANGE = "map_level_change",
  MAP_LAYER_CHANGE = "map_layer_change",
  ROUTE_REQUEST = "route_request",
  SEARCH_QUERY_SEND = "search_query_send",
  SEARCH_FEATURE_OPEN = "search_feature_open",
  ATTRIBUTION_OPEN = "attribution_open",
  SHARE_OPEN = "share_open",
  LEVEL_SELECTOR_TOUCH = "level_selector_touch",
  LAYER_SELECTOR_TOUCH = "layer_selector_touch",
  NORTH_ORIENTATION_ON = "north_orientation_on",
  NORTH_ORIENTATION_OFF = "north_orientation_off",
  SHOW_POSITION_ON = "show_position_on",
  SHOW_POSITION_OFF = "show_position_off",
  BOOKMARK_SELECT = "bookmark_select",
}

export interface MapLoadEvent {
  event_type: AnalyticsEvent.MAP_LOAD;
  event_data: {
    type: "embedded" | "native";
    source?: string;
  };
}

interface MapFeatureOpenEvent {
  event_type: AnalyticsEvent.MAP_FEATURE_OPEN;
  event_data: {
    lm_id: string;
    name: string;
  };
}

interface MapLevelChangeEvent {
  event_type: AnalyticsEvent.MAP_LEVEL_CHANGE;
  event_data: {
    // TODO: NB. building_from / _to currently have no DB values
    // building_from: string;
    floor_from: string;
    // building_to: string;
    floor_to: string;
  };
}

interface MapLayerChangeEvent {
  event_type: AnalyticsEvent.MAP_LAYER_CHANGE;
  event_data: {
    from: string;
    to: string;
  };
}

interface RouteRequestEvent {
  event_type: AnalyticsEvent.ROUTE_REQUEST;
  event_data:
    | {
        to_uid: number;
        to_name: string;
        from_latitude: number;
        from_longitude: number;
        step_free: boolean;
      }
    | {
        to_uid: number;
        to_name: string;
        from_uid: number;
        from_name: string;
        step_free: boolean;
      }
    | {
        to_latitude: number;
        to_longitude: number;
        from_uid: number;
        from_name: string;
        step_free: boolean;
      }
    | {
        to_latitude: number;
        to_longitude: number;
        from_latitude: number;
        from_longitude: number;
        step_free: boolean;
      };
}

interface SearchQuerySendEvent {
  event_type: AnalyticsEvent.SEARCH_QUERY_SEND;
  event_data: {
    query: string;
    quick_search: boolean;
    quick_search_id: number | null;
  };
}

interface SearchFeatureOpenEvent {
  event_type: AnalyticsEvent.SEARCH_FEATURE_OPEN;
  event_data: {
    lm_id: string;
    name: string;
  };
}

interface AttributionOpenEvent {
  event_type: AnalyticsEvent.ATTRIBUTION_OPEN;
}

interface ShareOpenEvent {
  event_type: AnalyticsEvent.SHARE_OPEN;
  event_data: string;
}

interface LevelSelectorTouchEvent {
  event_type: AnalyticsEvent.LEVEL_SELECTOR_TOUCH;
}

interface LayerSelectorTouchEvent {
  event_type: AnalyticsEvent.LAYER_SELECTOR_TOUCH;
}

interface NorthOrientationOnEvent {
  event_type: AnalyticsEvent.NORTH_ORIENTATION_ON;
}

interface NorthOrientationOffEvent {
  event_type: AnalyticsEvent.NORTH_ORIENTATION_OFF;
}

interface ShowPositionOnEvent {
  event_type: AnalyticsEvent.SHOW_POSITION_ON;
}

interface ShowPositionOffEvent {
  event_type: AnalyticsEvent.SHOW_POSITION_OFF;
}

interface BookmarkSelectEvent {
  event_type: AnalyticsEvent.BOOKMARK_SELECT;
  event_data: string;
}

export type AnalyticsEvents =
  | MapLoadEvent
  | MapFeatureOpenEvent
  | MapLevelChangeEvent
  | MapLayerChangeEvent
  | RouteRequestEvent
  | SearchQuerySendEvent
  | SearchFeatureOpenEvent
  | AttributionOpenEvent
  | ShareOpenEvent
  | LevelSelectorTouchEvent
  | LayerSelectorTouchEvent
  | NorthOrientationOnEvent
  | NorthOrientationOffEvent
  | ShowPositionOnEvent
  | ShowPositionOffEvent
  | BookmarkSelectEvent;
