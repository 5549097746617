import { IconType } from "@livingmap/core-ui-v2";
import { FeatureQueryResponse } from "../../../redux/services/types";
import { parseLanguageObject } from "../../../utils/parseLanguageObject";
import { Props as FeatureInformationPanelProps } from "../../../components/FeatureInformationPanel/FeatureInformationPanel";
import {
  TransportIdentifier,
  TransportInformation,
} from "../../../hooks/useTransportService";

export const getFeatureProps = (
  data: FeatureQueryResponse,
  transportInformation: TransportInformation | undefined,
  language: string,
  onPanelClose: () => void,
  onDirectionsClick: () => void,
  onTransportDepartureClick: (departure: TransportIdentifier) => void,
): FeatureInformationPanelProps => {
  const {
    categories,
    information,
    media,
    is_temporarily_closed,
    street_address,
    uid,
  } = data;

  return {
    dataQA: "feature-information-panel",
    category: parseLanguageObject(categories.category.name, language) || "",
    description: parseLanguageObject(information.description, language) || "",
    summary: parseLanguageObject(information.summary, language) || "",
    imageUrl: media.popup ? media.popup.url : "",
    panoramicImageUrl: media["360"] ? media["360"].url : undefined,
    isTemporarilyClosed: is_temporarily_closed,
    metaData: [
      ...(information.links
        ? [
            {
              icon: "WebsiteIcon" as IconType,
              type: "website",
              value: information.links.find((link) => link.type === "general")
                ?.url,
            },
          ]
        : []),
      ...(information.phone_number
        ? [
            {
              icon: "PhoneIcon" as IconType,
              type: "phone",
              value: information.phone_number,
            },
          ]
        : []),
      ...(street_address
        ? [
            {
              icon: "LocationIcon" as IconType,
              type: "address",
              value: street_address,
            },
          ]
        : []),
    ],
    name: parseLanguageObject(information.long_name, language) || "",
    operatingHours: information.operating_hours,
    uid: uid,
    onClose: onPanelClose,
    onDirectionsClick: onDirectionsClick,
    onDepartureClick: onTransportDepartureClick,
    transportInformation,
  };
};
