import { BottomSheetRef, Sheet } from "@livingmap/core-ui-v2";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearLocation } from "../../../../redux/slices/applicationSlice";
import { FeatureQueryResponse } from "../../../../redux/services/types";

import useResponsive from "../../../../hooks/useResponsive";
import { useSearchSuggestions } from "../../../../hooks/useSearchSuggestions";

import { push, replace } from "../../../../utils/navigate";
import { Path, QueryParamBinary, QueryParams } from "../../../../utils/types";

import SearchBar from "../../../../components/SearchBar/SearchBar";
import RoutingQuickActions from "../../components/RoutingQuickActions/RoutingQuickActions";
import SearchResults from "../../components/SearchResults/SearchResults";

import styles from "./MobileRoutingView.module.scss";
import { useTranslation } from "react-i18next";

const ROUTING_VIEW_RESULT_HEIGHT = 48;

interface Props {
  query: string;
  onSearchResultClick: (id: number, name: string) => void;
  searchResults?: FeatureQueryResponse[];
  isFetchingSearchResults: boolean;
}

export default function MobileRoutingView({
  query,
  isFetchingSearchResults,
  onSearchResultClick,
  searchResults,
}: Props) {
  const { Mobile } = useResponsive();
  const dispatch = useAppDispatch();
  const { state, pathname } = useLocation();
  const { fromName, toName } = useParams();
  const { t } = useTranslation();

  const { savedLocation, language } = useAppSelector(
    (state) => state.application,
  );

  const sheetRef = useRef<BottomSheetRef | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [initChange, setInitChange] = useState(false); // for handling showing quick actions when coming to this page with a start/end name
  const [showQuickActions, setShowQuickActions] = useState(true);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const handleOnChange = (value: string) => {
    setShowQuickActions(!value.length);
    setShowSearchResults(false);

    if (!initChange) {
      setInitChange(true);
    }

    setSearchSuggestion(value.length > 1 ? value : "");
  };

  const handleOnSearchSuggestion = (value: string) => {
    if (!initChange) {
      setInitChange(true);
      setShowQuickActions(false);
    }

    if (value === query) {
      setShowSearchResults(true);
      return;
    }

    dispatch(
      push({
        pathOrLocation: pathname,
        newQueryParams: {
          [QueryParams.QUERY]: value,
          [QueryParams.NAME_SEARCH]: QueryParamBinary.ENABLED,
        },
        state,
      }),
    );
  };

  const handleOnSearchSubmit = (value: string) => {
    if (!initChange) {
      setInitChange(true);
      setShowQuickActions(false);
    }

    if (value === query) {
      setShowSearchResults(true);
      return;
    }

    // if the user does a search, we just go to the same route with the search query param
    dispatch(
      push({
        pathOrLocation: pathname,
        newQueryParams: { [QueryParams.QUERY]: value },
        discardParams: [QueryParams.NAME_SEARCH],
        state,
      }),
    );
  };

  const { searchSuggestionsList, setSearchSuggestion } = useSearchSuggestions({
    className: styles.searchResults,
    handleOnClick: handleOnSearchSuggestion,
  });

  useEffect(() => {
    // if the query param for search is available, we use that instead of the start/end name
    if (query) {
      setSearchQuery(query);
      return;
    }

    const routeValue = state?.selectDestination ? toName : fromName;
    setSearchQuery(routeValue && routeValue !== "-" ? routeValue : "");
  }, [toName, query, fromName, state?.selectDestination]);

  useEffect(() => {
    if (searchResults) {
      setShowSearchResults(true);
    }
  }, [searchResults]);

  useEffect(() => {
    // highlights the text in the search bar when the user lands on the page with a start/end name
    setTimeout(() => searchInputRef.current?.select(), 200);
  }, []);

  return (
    <>
      {Mobile && (
        <Sheet
          ref={sheetRef}
          dataQA="routing-view-sheet"
          snapPoints={({ maxHeight, headerHeight }) => [
            headerHeight,
            ...(!showQuickActions && searchResults
              ? [
                  headerHeight +
                    ROUTING_VIEW_RESULT_HEIGHT +
                    ROUTING_VIEW_RESULT_HEIGHT / 2 +
                    20,
                ]
              : []), // 1.5 results + 20px margin
            maxHeight - 20,
          ]}
          open
          defaultSnap={({ maxHeight }) => maxHeight}
          onSpringStart={(event) => {
            window.scrollTo(0, 0);

            if (event.type === "SNAP" && event.source === "dragging") {
              searchInputRef.current?.blur(); // accessed from the child component
            }
          }}
          header={
            <SearchBar
              autoFocus
              persistValueOnBlur={false}
              ref={searchInputRef}
              compact
              roundedClearIcon
              cancelButton
              dataQA="routing-search-bar"
              placeholder={
                state?.selectDestination
                  ? t("route_page.destination_placeholder")
                  : t("route_page.origin_placeholder")
              }
              icon={
                state?.selectDestination ? "LocationIcon" : "RouteStartIcon"
              }
              iconStyle={
                state?.selectDestination ? { top: "1px" } : { top: "4px" }
              }
              value={searchQuery}
              onChange={handleOnChange}
              onClose={() => {
                dispatch(
                  replace({
                    pathOrLocation: savedLocation ? savedLocation : "/",
                  }),
                );

                if (savedLocation?.pathname === `/${Path.FEATURE}`)
                  dispatch(clearLocation());
              }}
              onSubmit={handleOnSearchSubmit}
              onFocus={() => {
                setTimeout(() => {
                  sheetRef.current?.snapTo(({ maxHeight }) => maxHeight);
                  searchInputRef.current?.select();
                }, 100);
              }}
            />
          }
        >
          {!initChange || showQuickActions ? (
            <RoutingQuickActions
              onChooseFromMapButtonClick={() => {
                sheetRef.current?.snapTo(({ headerHeight }) => headerHeight);
              }}
            />
          ) : searchResults && !isFetchingSearchResults && showSearchResults ? (
            <SearchResults
              dataQA="search-results"
              className={styles.searchResults}
              searchResults={searchResults}
              language={language}
              query={searchQuery}
              onSearchResultClick={onSearchResultClick}
            />
          ) : (
            searchSuggestionsList && (
              <div className={styles.container}>{searchSuggestionsList}</div>
            )
          )}
        </Sheet>
      )}
    </>
  );
}
