import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";

import "./index.css";
import "./global.scss";
import "@livingmap/core-ui-v2/dist/fonts.css";

import reportWebVitals from "./reportWebVitals";
import { checkAndCreateServiceWorker } from "./utils/checkAndCreateServiceWorker";
import { store, history } from "./redux/store";

import App from "./App";
import "./i18next";

const container = document.getElementById("root")!;
const root = createRoot(container);

if (process.env.NODE_ENV === "production") {
  checkAndCreateServiceWorker();
}

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
