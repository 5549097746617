import React from "react";

import styles from "./NoDepartures.module.scss";

interface Props {
  title?: string;
  subtitle?: string;
}

const NoDepartures: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <div className={styles.noDepartures}>
      {title && <span className={styles.title}>{title}</span>}
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
};

export default NoDepartures;
