import {
  RouteMilestoneFeature,
  RouteQueryResponse,
} from "./../redux/services/types/index";

export const generateFullRouteMilestones = (
  sequenceOrder: number[],
  segments: RouteQueryResponse["segments"]
) => {
  let features: RouteMilestoneFeature[] = [];

  sequenceOrder.forEach((segmentNum) => {
    const segment = segments[segmentNum];
    features = features.concat(segment.routeMilestones?.features || []);
  });

  return features;
};
