import { createContext } from "react";
import { FilterKing } from "@livingmap/core-mapping";

import ClusteredPinPlugin from "../components/Map/plugins/clustered-pin-control";
import FloorControl from "../components/Map/plugins/floor-control";
import InteractionPlugin from "../components/Map/plugins/interaction-control";
import RoutingPlugin from "../components/Map/plugins/routing-control";
import PositionPlugin from "../components/Map/plugins/position-control";
import { RouteFeature } from "../utils/types";

interface MapContextProps {
  mapLoaded: boolean | null;
  mapCentre: mapboxgl.LngLat | undefined;
  positionControl: PositionPlugin | null;
  interactionControl: InteractionPlugin | null;
  clusteredPinControl: ClusteredPinPlugin | null;
  filterControl: FilterKing | null;
  routingControl: RoutingPlugin | null;
  floorControl: FloorControl | null;
  chipContainerRef: HTMLDivElement | null;
  routeFeature: RouteFeature | null;
  clearRouteFeature: () => void | null;
}

export const MapContext = createContext<MapContextProps>({
  mapLoaded: null,
  mapCentre: undefined,
  positionControl: null,
  interactionControl: null,
  clusteredPinControl: null,
  filterControl: null,
  routingControl: null,
  floorControl: null,
  chipContainerRef: null,
  routeFeature: null,
  clearRouteFeature: () => {},
});
