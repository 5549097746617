import classNames from "classnames";
import { FC } from "react";

import { useCalculateOperatingHours } from "../../hooks/useCalculateOperatingHours";
import { OperatingHours } from "../../redux/services/types";

import { Status } from "../OpenTimeInfo/types";

import styles from "./OpenTimeSpan.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  dataQA: string;
  operatingHours: OperatingHours;
  isTemporarilyClosed?: boolean;
}

const OpenTimeSpan: FC<Props> = ({
  operatingHours,
  dataQA,
  isTemporarilyClosed,
}) => {
  const { status, statusCopy } = useCalculateOperatingHours(operatingHours);
  const { t } = useTranslation();

  return (
    ((statusCopy && operatingHours) || isTemporarilyClosed) && (
      <span
        data-qa={dataQA}
        className={classNames(styles.container, {
          [styles.closed]: status === Status.CLOSED || isTemporarilyClosed,
        })}
      >
        {isTemporarilyClosed
          ? t(`operating_hours_status_${Status.TEMP_CLOSED}`)
          : statusCopy}
      </span>
    )
  );
};

export default OpenTimeSpan;
