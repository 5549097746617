import { Routes, Route } from "react-router-dom";

import { Path } from "../utils/types";

import {
  HomeView,
  FeatureInformationPanelView,
  SearchResultsView,
  RoutingView,
  RouteView,
  JourneyView,
  JourneyOverviewView,
} from "../views";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomeView />}>
        <Route path={Path.FEATURE} element={<FeatureInformationPanelView />} />
        <Route path={Path.SEARCH} element={<SearchResultsView />} />
      </Route>
      <Route path={Path.ROUTE} element={<RoutingView />} />
      <Route path={Path.NAVIGATE} element={<RouteView />} />
      <Route path={Path.JOURNEY} element={<JourneyView />} />
      <Route path={Path.JOURNEY_OVERVIEW} element={<JourneyOverviewView />} />
    </Routes>
  );
}
