const RED_THRESHOLD = 0.299;
const GREEN_THRESHOLD = 0.587;
const BLUE_THRESHOLD = 0.114;
const INTENSITY_THRESHOLD = 186;

// splits a 6 digit hex colour code into individual RGB values
const hexToRgb = (hex: string) => {
  const parsedHex = parseInt(hex.replace("#", ""), 16);

  const red = (parsedHex >> 16) & 255;
  const green = (parsedHex >> 8) & 255;
  const blue = parsedHex & 255;

  return { red, green, blue };
};

// dictates whether text should be black or white based on the background colour
export const generateTextColourFromBackgroundColour = (hex: string) => {
  const { red, green, blue } = hexToRgb(hex);

  const intensity =
    red * RED_THRESHOLD + green * GREEN_THRESHOLD + blue * BLUE_THRESHOLD;

  if (intensity > INTENSITY_THRESHOLD) {
    return "#000000";
  } else {
    return "#ffffff";
  }
};
