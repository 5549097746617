import { useRef, useState } from "react";
import { Dialog, Button, Icon } from "@livingmap/core-ui-v2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";

import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";

import { AnalyticsEvent } from "../../utils/analyticsTypes";

import styles from "./ShareDialog.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  dataQA: string;
  isOpen: boolean;
  url: string;
  description?: string;
  maxWidth?: number;
  overlayType?: "light" | "dark";
  onClose: () => void;
}

const ShareDialog: React.FC<Props> = ({
  dataQA,
  isOpen,
  url,
  description,
  maxWidth = 320,
  overlayType = "dark",
  onClose,
}) => {
  const { logAnalyticsEvent } = usePostAnalyticsEvent();
  const { t } = useTranslation();

  const timeoutID = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleOnCopy = () => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
    }

    setIsCopied(true);

    timeoutID.current = setTimeout(() => {
      setIsCopied(false);
    }, 1500);

    logAnalyticsEvent({
      event_type: AnalyticsEvent.SHARE_OPEN,
      event_data: url,
    });
  };

  return (
    <Dialog
      dataQA={dataQA}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
      overlayType={overlayType}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h3 className={styles.headerText}>{t("share_dialog.title")}</h3>
          <Icon
            dataQA="close-button"
            type="CloseIcon"
            onClick={onClose}
            className={styles.closeIcon}
          />
        </div>
        <div data-qa="qr-code" className={styles.qrCode}>
          <QRCode value={url} size={160} />
          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.urlContainer}>
            <p className={styles.url}>{url}</p>
          </div>
          <CopyToClipboard text={url} onCopy={handleOnCopy}>
            <Button
              dataQA="copy-link-button"
              color="black"
              icon="ContentCopyIcon"
              onClick={() => {}}
              className={styles.button}
              rounded
            >
              {isCopied
                ? t("share_dialog.button_text_confirmed")
                : t("share_dialog.button_text")}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </Dialog>
  );
};

export default ShareDialog;
