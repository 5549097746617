import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./Panel.module.scss";

interface Props {
  dataQA: string;
  className?: string;
  children: ReactNode;
}

const Panel: FC<Props> = ({ children, dataQA, className }) => {
  return (
    <div data-qa={dataQA} className={classNames(styles.container, className)}>
      {children}
    </div>
  );
};

export default Panel;
