import axios from "axios";

function postAssetPath(project: string) {
  return `/v1/project/${project}/assets`;
}

function postAssetPingPath(project: string, assetUuid: string) {
  return `/v1/project/${project}/assets/${assetUuid}/pings`;
}

function getAssetPingsPath(project: string, assetUuid: string): string {
  return `/v1/project/${project}/assets/${assetUuid}/pings`;
}

function getAssetCategoriesPath(project: string): string {
  return `/v1/project/${project}/asset-categories`;
}

export interface Ping {
  position: {
    latitude: number;
    longitude: number;
  };
  timestamp: string;
  accuracy: number | null;
  heading: number | null;
  metadata: {
    // may include speed, altitude and altitudeAcuracy - none of which I think we actually use
    [key: string]: string | null;
  } | null;
}

export interface AssetCategory {
  uuid: string;
  parameterCategories: {
    uuid: string;
    primary: boolean;
  }[];
}

export default class LocatorAPI {
  private readonly token: string;
  private readonly host: string;

  constructor(host: string, token: string) {
    this.host = host;
    this.token = token;
  }

  public async postAsset(project: string, body: any): Promise<string> {
    const assetResponse = await axios.post(
      `${this.host}${postAssetPath(project)}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
    return assetResponse.data.uuid;
  }

  public async postAssetPing(
    project: string,
    assetUuid: string,
    body: any,
  ): Promise<void> {
    await axios.post(
      `${this.host}${postAssetPingPath(project, assetUuid)}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  }

  public async getAssetPings(
    project: string,
    assetUuid: string,
  ): Promise<Ping[]> {
    const response = await axios.get<Ping[]>(
      `${this.host}${getAssetPingsPath(project, assetUuid)}?order=asc`,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
    return response.data;
  }

  public async getAssetCategories(project: string): Promise<AssetCategory[]> {
    const response = await axios.get<AssetCategory[]>(
      `${this.host}${getAssetCategoriesPath(project)}`,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );

    return response.data;
  }
}
