import { Icon, IconType } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { FC } from "react";

import styles from "./RouteStepBanner.module.scss";

export interface RouteStepBannerProps {
  dataQA: string;
  className?: string;
  text: string;
  subText?: string;
  time?: string;
  icon?: IconType;
  accent?: boolean;
  size?: "small" | "medium";
}

const RouteStepBanner: FC<RouteStepBannerProps> = ({
  dataQA,
  text,
  className,
  icon = "RouteStartIcon",
  subText,
  time,
  accent = false,
  size = "small",
}) => {
  return (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, className, {
        [styles.accent]: accent,
        [styles.medium]: size === "medium",
      })}
    >
      <div className={styles.iconContainer}>
        <Icon
          dataQA="icon"
          type={icon}
          className={styles.icon}
          width={size === "medium" ? 20 : 14}
          height={size === "medium" ? 20 : 14}
        />
      </div>
      <div className={styles.contentContainer}>
        {subText && <span className={styles.subText}>{subText}</span>}
        <span className={styles.mainText}>{text}</span>
      </div>
      {time && <span className={styles.time}>{time}</span>}
    </div>
  );
};

export default RouteStepBanner;
