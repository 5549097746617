import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../redux/hooks";
import useResponsive from "./useResponsive";

import { buildRoutingPath } from "../utils/buildRoutingPath";
import { replace } from "../utils/navigate";
import { QueryParam, RoutingPath } from "../utils/types";

// redirects to the route page if the user is not on mobile
export const useRedirectToDesktopRoute = () => {
  const dispatch = useAppDispatch();
  const { fromName, fromId, toName, toId } = useParams();
  const { Mobile } = useResponsive();

  useEffect(() => {
    if (!Mobile) {
      dispatch(
        replace({
          pathOrLocation: buildRoutingPath(RoutingPath.NAVIGATE, {
            fromId,
            fromName,
            toId,
            toName,
          }),
          discardParams: [QueryParam.MILESTONE_INDEX],
        }),
      );
    }
  }, [Mobile, dispatch, fromId, fromName, toId, toName]);
};
