import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./TransportTimetable.module.scss";
import { Chip } from "@livingmap/core-ui-v2";
import { TransportIdentifier } from "../../hooks/useTransportService";
import { generateTextColourFromBackgroundColour } from "../../utils/textColour";
import NoDepartures from "../NoDepartures/NoDepartures";

export type TransportTimetableDeparture = {
  transportIdentifier: TransportIdentifier;
  destination: string;
  departureTime: string;
  departureTimeISO: string;
};

interface Props {
  dataQA: string;
  className?: string;
  departures: TransportTimetableDeparture[];
  onDepartureClick?: (transportIdentifier: TransportIdentifier) => void;
}

const generateDepartureListItems = (
  departures: TransportTimetableDeparture[],
  onDepartureClick?: (transportIdentifier: TransportIdentifier) => void,
): ReactNode[] =>
  departures.map((departure, index) => (
    <li
      className={styles.listItem}
      key={index}
      onClick={() =>
        onDepartureClick && onDepartureClick(departure.transportIdentifier)
      }
    >
      <Chip
        dataQA="departure-chip"
        className={styles.chip}
        onClick={() => {}}
        children={departure.transportIdentifier.code}
        style={{
          backgroundColor: departure.transportIdentifier.colour,
          color: generateTextColourFromBackgroundColour(
            departure.transportIdentifier.colour,
          ),
        }}
        active
      />
      <span className={styles.destination}>{departure.destination}</span>
      <span className={styles.departureTime}>{departure.departureTime}</span>
    </li>
  ));

const TransportTimetable: FC<Props> = ({
  departures,
  className,
  dataQA,
  onDepartureClick,
}) => {
  const departureListItems = generateDepartureListItems(
    departures,
    onDepartureClick,
  );

  return (
    <ul data-qa={dataQA} className={classNames(styles.container, className)}>
      {departureListItems.length ? (
        departureListItems
      ) : (
        <NoDepartures title="No upcoming departures" />
      )}
    </ul>
  );
};

export default TransportTimetable;
