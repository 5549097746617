import { useAppSelector } from "../redux/hooks";
import { Floor } from "../redux/services/types";
import { parseLanguageObject } from "../utils/parseLanguageObject";

export const useFloorData = (floors?: Floor[]) => {
  const { queryParamsConfig, language } = useAppSelector(
    (state) => state.application,
  );

  const mappedFloors =
    floors
      ?.slice()
      .reverse()
      .map((floor) => ({
        ...floor,
        name: parseLanguageObject(floor.name, language) || "",
      })) || [];

  const queryParamFloor =
    mappedFloors.find(
      (floor) => floor.short_name === queryParamsConfig.floor,
    ) || mappedFloors.find((floor) => floor.default);

  return { mappedFloors, queryParamFloor };
};
