import { useMediaQuery } from "react-responsive";

export const breakpoints = {
  Mobile: 743,
  Default: 744,
};

const useResponsive = () => {
  const Mobile = useMediaQuery({ maxWidth: breakpoints.Mobile });

  const Default = useMediaQuery({ minWidth: breakpoints.Default });

  return { Mobile, Default };
};

export default useResponsive;
