import { useMemo } from "react";

import { RECENT_SEARCHES_KEY } from "../constants";

import { RecentQuery } from "../utils/types";

interface RecentSearchOptions {
  quickSearch?: boolean;
}

interface RecentPoiOptions {
  name: string;
  address: string;
}

export default function useRecentQueries(localStorageKey: string, max = 5) {
  const localRecentSearches = localStorage.getItem(localStorageKey);

  const parsedRecentQueries = useMemo(
    (): RecentQuery[] =>
      localRecentSearches ? JSON.parse(localRecentSearches) : [],
    [localRecentSearches],
  );

  const removeDuplicatedRecentQuery = (
    value: string | number,
    options?: RecentSearchOptions | RecentPoiOptions,
  ) => {
    const index = parsedRecentQueries.findIndex((search) => {
      // we need to do an additional check for recent searches to see if it's a quick search
      // as the id from a quick search could match a typed search e.g. id = 1 and value = 1
      if (localStorageKey === RECENT_SEARCHES_KEY)
        return (
          search.value === value &&
          search.quickSearch ===
            (options && "quickSearch" in options && options.quickSearch)
        );

      return search.value === value;
    });

    const existingRecentQuery = parsedRecentQueries[index];

    // if recent query exists, remove it from array and reindex the rest
    if (existingRecentQuery) {
      const reindexedRecentQueries = parsedRecentQueries
        .filter((search) => search.key !== existingRecentQuery.key)
        .map((search) => ({
          ...search,
          ...(search.key > existingRecentQuery.key && { key: search.key - 1 }),
        }));

      return reindexedRecentQueries;
    }

    return parsedRecentQueries;
  };

  const addRecentQuery = (
    value: string | number,
    options?: RecentSearchOptions | RecentPoiOptions,
  ) => {
    const recentQueries = removeDuplicatedRecentQuery(value, options);

    // if recentQueries.length === max, remove the oldest query and add the new one
    if (recentQueries.length === max) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify([
          ...recentQueries
            .filter((search) => search.key !== 0)
            .map((search) => ({ ...search, key: search.key - 1 })),
          { value, key: max - 1, ...options }, // new query added to the end of array
        ]),
      );
    } else {
      // else, add the new query to the end of array
      localStorage.setItem(
        localStorageKey,
        JSON.stringify([
          ...recentQueries,
          { value, key: recentQueries.length, ...options },
        ]),
      );
    }
  };

  return { parsedRecentQueries, addRecentQuery };
}
