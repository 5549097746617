import classNames from "classnames";
import { FC, ReactNode, useEffect, useState } from "react";
import { Icon } from "@livingmap/core-ui-v2";
import { useSpring, animated } from "@react-spring/web";

import { useWindowSize } from "../../hooks/useWindowSize";

import styles from "./CollapsablePanel.module.scss";

export interface Props {
  dataQA: string;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  isOpen: boolean;
  header: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  footerClassName?: string;
  separator?: boolean;
}

const CollapsablePanel: FC<Props> = ({
  isOpen,
  dataQA,
  className,
  headerClassName,
  contentClassName,
  header,
  children,
  separator = false,
  footer,
  footerClassName,
}) => {
  // This is the height of the bottom ui elements (quick search tags)
  // Hardcoding it as a constant rather than prop drilling it through a ref as it's unlikely to change
  const UI_BOTTOM_REMAINDER_HEIGHT = 48 + 16;

  const [maximised, setMaximised] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [uiTopRemainderHeight, setUiTopRemainderHeight] = useState(0);
  const [minMaxToggleHeight, setMinMaxToggleHeight] = useState(0);

  const [animatedHeight, animateHeight] = useSpring(() => ({}));
  const { height } = useWindowSize();

  const headerRef = (node: HTMLDivElement) => {
    if (node) {
      const { height, top } = node.getBoundingClientRect();

      setHeaderHeight(height);
      setUiTopRemainderHeight(top);
    }
  };

  const minMaxToggleRef = (node: HTMLDivElement) => {
    if (node) {
      const { height } = node.getBoundingClientRect();

      setMinMaxToggleHeight(height);
    }
  };

  // This handles the expansion and contraction of the main panel component
  useEffect(() => {
    animateHeight.start({
      maxHeight: maximised ? `${window.innerHeight}px` : `${headerHeight}px`,
    });
  }, [animateHeight, headerHeight, maximised, height]);

  return isOpen ? (
    <div className={classNames(styles.container, className)} data-qa={dataQA}>
      <animated.div
        style={{
          overflow: "hidden",
          ...animatedHeight,
        }}
      >
        <div
          ref={headerRef}
          className={classNames(styles.header, headerClassName, {
            [styles.separator]: separator,
          })}
        >
          {header}
        </div>
        {children && (
          <div
            className={classNames(styles.content, contentClassName)}
            // Height of panel subtract the header height and other ui element heights to get maxHeight of the scrollable content
            style={{
              maxHeight: `${
                window.innerHeight -
                headerHeight -
                uiTopRemainderHeight -
                UI_BOTTOM_REMAINDER_HEIGHT -
                minMaxToggleHeight -
                23
              }px`,
            }} // -23 to make the height between the bottom of the viewport and the panel to equal 92px according to the designs
          >
            {children}
          </div>
        )}
      </animated.div>
      {footer && <div className={footerClassName}>{footer}</div>}
      {children && (
        <div
          className={styles.minMaxToggle}
          onClick={() => setMaximised(!maximised)}
          ref={minMaxToggleRef}
        >
          <Icon
            dataQA="min-max-toggle-icon"
            type={maximised ? "ChevronUpIcon" : "ChevronDownIcon"}
          />
        </div>
      )}
    </div>
  ) : null;
};

export default CollapsablePanel;
