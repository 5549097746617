import { LocatorLogger } from "./LocatorLogger";
import { LocatorGeolocationProvider } from "./LocatorGeolocationProvider";
import LocatorAPI from "./LocatorAPI";

import { ENVIRONMENT } from "../../constants";

/**
 * API token required for using the Locator API.
 *
 * The token can be readonly if you're only replaying asset positions.
 *
 * The token needs to have write permissions if you're logging positions to Locator.
 */
const LOCATOR_LOGGING_TOKEN_QUERY_PARAM = "positionLoggingToken";

/**
 * If this query parameter is defined (along with LOCATOR_LOGGING_TOKEN_QUERY_PARAM) then
 * will initialiseLocatorDebugIntegration will return an alternate Geolocation implementation
 * which will feed pings (in time order) from this asset into our location callbacks.
 */
const LOCATOR_ASSET_REPLAY_QUERY_PARAM = "replayAssetUuid";

let initialised = false;
let locatorGeolocationProvider: LocatorGeolocationProvider | null = null;
let locatorLogger: LocatorLogger | null = null;

const LOCATOR_PROJECT = "location_logging";

export function initialiseLocatorDebugTools(): [
  LocatorLogger | null,
  LocatorGeolocationProvider | null,
] {
  if (initialised) return [locatorLogger, locatorGeolocationProvider];
  initialised = true;

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(LOCATOR_LOGGING_TOKEN_QUERY_PARAM);
  const replayAssetUuid = urlParams.get(LOCATOR_ASSET_REPLAY_QUERY_PARAM);

  if (!token) {
    return [null, null];
  }

  const locatorHost = `https://locator-api.${ENVIRONMENT}.livingmap.com`;

  if (token && replayAssetUuid) {
    console.warn(`Locator asset position replay enabled`);
    locatorGeolocationProvider = new LocatorGeolocationProvider(
      new LocatorAPI(locatorHost, token),
      LOCATOR_PROJECT,
      replayAssetUuid,
    );
  } else {
    console.warn(`Locator position logging enabled`);
    locatorLogger = new LocatorLogger({
      project: LOCATOR_PROJECT,
      token,
      locatorHost,
    });
    locatorLogger.init();
  }

  return [locatorLogger, locatorGeolocationProvider];
}
