import { useEffect, useCallback } from "react";
import { FloorConfig } from "../components/Map/plugins/floor-control";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setRoutingUserActiveFloor } from "../redux/slices/applicationSlice";

export const useRoutingUserActiveFloor = ({
  getActiveFloor,
}: {
  getActiveFloor: () => FloorConfig | null | undefined;
}) => {
  const dispatch = useAppDispatch();

  const { routingUserActiveFloor } = useAppSelector(
    (state) => state.application,
  );

  const updateRoutingUserActiveFloor = useCallback(() => {
    const activeFloor = getActiveFloor();
    activeFloor && dispatch(setRoutingUserActiveFloor(activeFloor));
  }, [dispatch, getActiveFloor]);

  useEffect(() => {
    if (!routingUserActiveFloor) {
      updateRoutingUserActiveFloor();
    }
  }, [
    dispatch,
    getActiveFloor,
    routingUserActiveFloor,
    updateRoutingUserActiveFloor,
  ]);

  return { updateRoutingUserActiveFloor };
};
