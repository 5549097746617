import { FlightConnectorDetails } from "../redux/services/types";
import { push } from "./navigate";
import { buildRoutingPath } from "./buildRoutingPath";
import { QueryParams, RoutingPath, Path } from "./types";
import type { AppDispatch } from "../redux/store";

export interface PluginRequest {
  departure: string | undefined;
  arrival?: string | undefined;
}

export const decodePlugin = (pluginString: string): PluginRequest => {
  const stringSplit = pluginString.split(":");
  const outboundFlightNumber = stringSplit[1];

  const pluginRequest: PluginRequest = {
    departure: outboundFlightNumber,
  };
  if (stringSplit.length > 2) {
    const inboundFlightNumber = stringSplit[2];
    pluginRequest.arrival = inboundFlightNumber;
  }
  return pluginRequest;
};

export const handlePluginResponse = (
  flightDetails: FlightConnectorDetails | undefined,
  dispatch: AppDispatch,
) => {
  if (flightDetails) {
    const toName = flightDetails.departure.properties.popup_header;
    const toId = flightDetails.departure.properties.uid;
    const toLmId = flightDetails.departure.properties.lm_id;
    if (flightDetails.arrival) {
      const fromName = flightDetails.arrival.properties.popup_header;
      const fromId = flightDetails.arrival.properties.uid;
      dispatch(
        push({
          pathOrLocation: buildRoutingPath(RoutingPath.NAVIGATE, {
            fromName,
            fromId,
            toName,
            toId,
          }),
          discardParams: [QueryParams.PLUGIN],
        }),
      );
    } else {
      // feature mode
      dispatch(
        push({
          pathOrLocation: `/${Path.FEATURE}`,
          newQueryParams: { [QueryParams.FEATURE_ID]: toLmId },
          discardParams: [QueryParams.PLUGIN],
        }),
      );
    }
  }
};
