import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  QueryParamsConfig,
  setQueryParamsConfig,
  uiControlsConfig,
  mapGesturesConfig,
  uiSearchConfig,
  uiFeatureConfig,
  geoLocationConfig,
  floorConfig,
  DebugConfig,
  PluginConfig,
  sourceConfig,
  consoleLocationConfig,
} from "../redux/slices/applicationSlice";
import { useAppDispatch } from "../redux/hooks";
import { Floor } from "../redux/services/types";
import { MapQueryParam } from "../utils/types";

const useQueryParamConfig = ({
  defaultFloor,
}: {
  defaultFloor: Floor | undefined;
}) => {
  // Get the search params from the URL
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  // Get the ui-controls config from the URL
  const uiControlsConfig = (searchParams.get(MapQueryParam.UI_CONTROLS) ||
    "show") as uiControlsConfig;

  // Get the map-gestures config from the URL
  const mapGesturesConfig = (searchParams.get(MapQueryParam.UI_GESTURES) ||
    "enable") as mapGesturesConfig;

  // get the ui-search config from the URL
  const uiSearchConfig = (searchParams.get(MapQueryParam.UI_SEARCH) ||
    "show") as uiSearchConfig;

  const uiFeatureConfig = (searchParams.get(MapQueryParam.UI_FEATURE) ||
    "show") as uiFeatureConfig;

  // get the geolocation config from the URL
  const geolocationConfig = (searchParams.get(MapQueryParam.GEOLOCATION) ||
    "enable") as geoLocationConfig;

  // get the floor level config from the URL
  const floorLevelConfig = (searchParams.get(MapQueryParam.FLOOR) ||
    defaultFloor) as floorConfig;

  // get markers config from the URL
  const markersConfig = searchParams.get(MapQueryParam.MARKER) || null;

  // get the callback url from the URL
  const callbackConfig = searchParams.get(MapQueryParam.CALLBACK) || null;

  // get the plugin config from the URL
  const pluginConfig = searchParams.get(MapQueryParam.PLUGIN) as PluginConfig;

  // get the debug config from the URL
  const debugConfig = searchParams.get(
    MapQueryParam.DEBUG || "disable",
  ) as DebugConfig;

  // get switch testing positioning system
  const consoleLocationConfig = searchParams.get(
    MapQueryParam.CONSOLE_LOCATION || "disable",
  ) as consoleLocationConfig;

  // get the source config from the URL
  const sourceConfig = searchParams.get(MapQueryParam.SOURCE) as sourceConfig;

  useEffect(() => {
    const queryParamsConfig: QueryParamsConfig = {
      "ui-controls": uiControlsConfig,
      "ui-gestures": mapGesturesConfig,
      "ui-search": uiSearchConfig,
      "ui-feature": uiFeatureConfig,
      geolocation: geolocationConfig,
      floor: floorLevelConfig,
      markers: markersConfig,
      callback: callbackConfig,
      plugin: pluginConfig,
      debug: debugConfig,
      consoleLocation: consoleLocationConfig,
      source: sourceConfig,
    };

    // Set the ui-controls config in the redux store
    dispatch(setQueryParamsConfig(queryParamsConfig));
  }, [
    dispatch,
    uiControlsConfig,
    mapGesturesConfig,
    uiSearchConfig,
    uiFeatureConfig,
    geolocationConfig,
    floorLevelConfig,
    markersConfig,
    callbackConfig,
    pluginConfig,
    debugConfig,
    consoleLocationConfig,
    sourceConfig,
  ]);
};

export default useQueryParamConfig;
