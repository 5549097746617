import { FC, useRef } from "react";

import styles from "./DestinationSearchBar.module.scss";
import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";

interface Props {
  dataQA: string;
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const DestinationSearchBar: FC<Props> = ({
  dataQA,
  className,
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div data-qa={dataQA} className={classNames(styles.container, className)}>
      <Icon
        dataQA="destination-filter-icon"
        type="SearchIcon"
        className={styles.searchIcon}
      />
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Escape") {
            inputRef.current?.blur();
          }
        }}
        className={styles.input}
        placeholder="Find a destination"
      />
      {value.length > 0 && (
        <Icon
          dataQA="destination-filter-clear-button"
          type="ClearOutlinedIcon"
          className={styles.clearButton}
          onClick={() => onChange("")}
        />
      )}
    </div>
  );
};

export default DestinationSearchBar;
