import classNames from "classnames";
import { forwardRef, ForwardRefRenderFunction } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { OperatingHours } from "../../redux/services/types";
import { Status } from "../OpenTimeInfo/types";
import OpenTimeSpan from "../OpenTimeSpan/OpenTimeSpan";

import styles from "./SearchResult.module.scss";

interface Props {
  dataQA: string;
  className?: string;
  title?: string | null;
  description?: string | null;
  address?: string | null;
  operatingHours?: OperatingHours | null;
  imageUrl?: string | null;
  isLoading?: boolean;
  isTemporarilyClosed?: boolean;
  onClick?: () => void;
}

const LoadingComponent: ForwardRefRenderFunction<HTMLDivElement, {}> = (
  _,
  ref
) => {
  return (
    <div className={styles.loadingContainer} ref={ref}>
      <div className={styles.loadingContent}>
        <div
          className={classNames(styles.loadingTitle, styles.skeletonBox)}
        ></div>
        <div
          className={classNames(styles.loadingDescription, styles.skeletonBox)}
        ></div>
        <div
          className={classNames(styles.loadingAddress, styles.skeletonBox)}
        ></div>
        <div
          className={classNames(styles.loadingTimes, styles.skeletonBox)}
        ></div>
      </div>
      <div className={classNames(styles.loadingImage, styles.skeletonBox)} />
    </div>
  );
};

const LoadingComponentWithForwardRef = forwardRef(LoadingComponent);

const SearchResult: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    dataQA,
    className,
    title,
    description,
    address,
    operatingHours,
    imageUrl,
    isLoading = false,
    isTemporarilyClosed = false,
    onClick,
  },
  ref
) => {
  return !isLoading ? (
    <div
      data-qa={dataQA}
      className={classNames(styles.container, className)}
      onClick={onClick}
      ref={ref}
    >
      <div className={styles.content} data-qa="content">
        {title && (
          <LinesEllipsis
            className={styles.title}
            text={title}
            maxLine="1"
            ellipsis="..."
            component="p"
            basedOn="letters"
          />
        )}
        {description && (
          <LinesEllipsis
            className={styles.description}
            text={description}
            maxLine="2"
            ellipsis="..."
            component="p"
            basedOn="letters"
          />
        )}
        {address && (
          <LinesEllipsis
            className={styles.address}
            text={address}
            maxLine="1"
            ellipsis="..."
            component="p"
            basedOn="letters"
          />
        )}
        {!isTemporarilyClosed ? (
          operatingHours && (
            <OpenTimeSpan
              dataQA="open-closed-info"
              operatingHours={operatingHours}
            />
          )
        ) : (
          <span className={styles.tempClosed}>{Status.TEMP_CLOSED}</span>
        )}
      </div>
      {imageUrl && (
        <img
          data-qa="image-container"
          className={styles.imageContainer}
          src={imageUrl}
          alt="feature"
        />
      )}
    </div>
  ) : (
    <LoadingComponentWithForwardRef ref={ref} />
  );
};

export default forwardRef(SearchResult);
