import { useAppSelector } from "../redux/hooks";
import { RawRegion } from "../redux/services/types";
import { parseLanguageObject } from "../utils/parseLanguageObject";

export const useRegionData = (rawRegions?: RawRegion[]) => {
  const { language } = useAppSelector((state) => state.application);

  const regions =
    rawRegions?.map((region) => ({
      ...region,
      name: parseLanguageObject(region.name, language) || "",
      floors: region.floors.map((floor) => ({
        ...floor,
        name: parseLanguageObject(floor.name, language) || "",
      })),
    })) || [];

  return { regions };
};
