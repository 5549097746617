import { StoryFn, Meta } from "@storybook/react";

import SearchTags from "./SearchTags";

const exampleData = [
  {
    id: 1,
    name: "Food & Drink",
  },
  {
    id: 2,
    name: "Coffee",
  },
  {
    id: 3,
    name: "Toilets",
  },
  {
    id: 4,
    name: "Shops",
  },
  {
    id: 5,
    name: "Lounges",
  },
  {
    id: 6,
    name: "ATMs",
  },
  {
    id: 7,
    name: "Drinking Water",
  },
  {
    id: 8,
    name: "Device Charging",
  },
  {
    id: 9,
    name: "Food & Drink 2",
  },
  {
    id: 10,
    name: "Coffee 2",
  },
  {
    id: 11,
    name: "Toilets 2",
  },
  {
    id: 12,
    name: "Shops 2",
  },
  {
    id: 13,
    name: "Lounges 2",
  },
  {
    id: 14,
    name: "ATMs 2",
  },
  {
    id: 15,
    name: "Drinking Water 2",
  },
  {
    id: 16,
    name: "Device Charging 2",
  },
];

export default {
  title: "SearchTags",
  component: SearchTags,
  argTypes: {},
} as Meta<typeof SearchTags>;

const Template1: StoryFn<typeof SearchTags> = (args) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 2rem)",
      }}
    >
      <div style={{ width: "75%" }}>
        <SearchTags {...args} />
      </div>
    </div>
  );
};

export const Desktop = Template1.bind({});
Desktop.args = {
  dataQA: "search-tags",
  data: exampleData,
  enableArrows: true,
  initialOffset: 0,
  itemGap: 8,
  onClick: (seletion) => console.log("You selected: ", seletion),
};

const Template2: StoryFn<typeof SearchTags> = (args) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 2rem)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40%",
          maxWidth: "375px",
          height: "75%",
          border: "2px solid black",
          borderRadius: "24px",
        }}
      >
        <SearchTags {...args} />
      </div>
    </div>
  );
};

export const Mobile = Template2.bind({});
Mobile.args = {
  dataQA: "search-tags",
  data: exampleData,
  enableArrows: false,
  initialOffset: 16,
  itemGap: 8,
  onClick: (seletion) => console.log("You selected: ", seletion),
};
