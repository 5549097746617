import { useSearchParams } from "react-router-dom";
import { QueryParamBinary, QueryParams } from "../utils/types";

export const useFeatureSearchParams = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get(QueryParams.QUERY) || "";
  const isQuickSearch =
    searchParams.get(QueryParams.QUICK_SEARCH) === QueryParamBinary.ENABLED;
  const isNameSearch =
    searchParams.get(QueryParams.NAME_SEARCH) === QueryParamBinary.ENABLED;
  return { query, isQuickSearch, isNameSearch };
};
