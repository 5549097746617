import { format } from "date-fns";

import { convertTimeToDate } from "../utils";

export const formatTime = (time: string) => {
  const formattedTime = convertTimeToDate(time);
  const timeFormat = formattedTime.getMinutes() !== 0 ? "h.mm aaa" : "h aaa";

  return format(formattedTime, timeFormat);
};
