import { useCallback, useEffect, useRef, useState } from "react";
import { useDrag } from "@use-gesture/react";

import { getClosestNumber } from "../utils";
import { useSpring } from "@react-spring/web";

export interface SliderOptions {
  enableArrows: boolean;
  initialOffset?: number;
  itemGap?: number;
}

/**
 * A hook to handle slider-based logic
 *
 * @param object {@link SliderOptions}
 * @returns object containing animation styles and slider helpers
 */
export const useSlider = ({
  enableArrows,
  initialOffset = 0,
  itemGap = 8,
}: SliderOptions) => {
  const [firstItemIsVisible, setFirstItemIsVisible] = useState(true);
  const [lastItemIsVisible, setLastItemIsVisible] = useState(true);
  const [sliderItems, setSliderItems] = useState<HTMLElement[]>([]);
  const [sliderContainer, setSliderContainer] = useState<HTMLElement | null>();
  const snapPoints = useRef<number[]>([]);
  const initialPosition = useRef(0);
  const sliderIndex = useRef(0);
  const sliderWidth = useRef(0);
  const sliderItemSizes = useRef<DOMRect[]>([]);

  useEffect(() => {
    if (!sliderContainer) return;

    const sliderItemList = Array.from(
      sliderContainer.childNodes
    ) as HTMLElement[];
    sliderItemList.forEach((item) => {
      item.style.padding = `0 ${itemGap / 2}px`;
    });

    setSliderItems(Array.from(sliderContainer.childNodes) as HTMLElement[]);
  }, [sliderContainer, itemGap]);

  const [animationStyles, springApi] = useSpring(() => {});

  // Used so we can build an array of snap points for each slider item
  const handleSliderItemResizeObserver = useCallback(
    (entries: ResizeObserverEntry[]) => {
      entries.forEach((entry, index) => {
        if (index === 0) {
          sliderItemSizes.current = [entry.target.getBoundingClientRect()];
        } else {
          sliderItemSizes.current.push(entry.target.getBoundingClientRect());
        }
      });

      if (!sliderContainer || sliderItemSizes.current.length === 0) return;

      sliderWidth.current = sliderItemSizes.current.reduce(
        (total, currentItem) => {
          return total + currentItem.width;
        },
        0
      );

      const updatedSnapPoints: number[] = [];

      sliderItemSizes.current.forEach((_, index) => {
        if (index === 0) {
          return updatedSnapPoints.push(initialPosition.current);
        }

        updatedSnapPoints.push(
          updatedSnapPoints[index - 1] -
            sliderItemSizes.current[index - 1].width
        );
      });

      snapPoints.current = updatedSnapPoints;
    },
    [sliderContainer]
  );

  const sliderItemResizeObserver = new ResizeObserver(
    handleSliderItemResizeObserver
  );

  // This will ensure the slider items are centred while the total slider width is smaller or equal to the slider container's width
  const handleSliderContainerResizeObserver = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!sliderContainer) return;

      for (const entry of entries) {
        const element = entry.target as HTMLElement;
        const { width: sliderContainerWidth } = element.getBoundingClientRect();

        if (sliderWidth.current > sliderContainerWidth) {
          element.classList.remove("centre-slider");
        } else {
          element.classList.add("centre-slider");
        }
      }
    },
    [sliderContainer]
  );

  const sliderContainerResizeObserver = new ResizeObserver(
    handleSliderContainerResizeObserver
  );

  // Used to update the state if the first or last slider items become visible in the viewport, which is used to render the arrows conditionally
  const handleIntersectionObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.target.nextSibling) {
          setFirstItemIsVisible(entry.isIntersecting);
        } else {
          setLastItemIsVisible(entry.isIntersecting);
        }
      }
    },
    []
  );

  const intersectionObserver = new IntersectionObserver(
    handleIntersectionObserver,
    {
      threshold: 1,
    }
  );

  useEffect(() => {
    if (!sliderContainer || sliderItems.length === 0) return;

    sliderItems.forEach((target, index) => {
      if (!target) return;

      if (index === 0) {
        initialPosition.current = enableArrows
          ? 20 + itemGap / 2
          : initialOffset;
        springApi.start({ x: initialPosition.current });
      }

      if (index === 0 || index === sliderItems.length - 1) {
        intersectionObserver.observe(target);
      }

      sliderItemResizeObserver.observe(target);
    });

    sliderContainerResizeObserver.observe(sliderContainer);

    return () => {
      sliderItems.forEach((target, index) => {
        if (!target) return;

        if (index === 0 || index === sliderItems.length - 1) {
          intersectionObserver.unobserve(target);
        }

        sliderItemResizeObserver.unobserve(target);
      });

      sliderContainerResizeObserver.unobserve(sliderContainer);
    };
    // TODO: investigate cause of re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderContainer, sliderItems]);

  const dragAttributes = useDrag(
    ({ active, offset: [offsetX] }) => {
      let snapPositionIndex = 0;

      if (!active) {
        // If the drag event is no longer active, find the closest snap point in relation to the current x offset and snap to that point
        const closestSnapPoint = getClosestNumber(snapPoints.current, offsetX);

        snapPositionIndex = snapPoints.current.findIndex(
          (point) => point === closestSnapPoint
        );
        sliderIndex.current = snapPositionIndex;

        springApi.start({ x: closestSnapPoint });
        return;
      }

      springApi.start({ x: offsetX, immediate: true });
    },
    {
      from: () => [animationStyles.x.get(), 0],
      bounds: () => {
        const sliderContainerWidth =
          sliderContainer?.getBoundingClientRect().width;

        if (!sliderContainerWidth) {
          return { right: initialPosition.current };
        }

        const sliderItemSizeCopy = [...sliderItemSizes.current];

        /**
         * Adding the incremental sum of each individual slider item in reverse order allows us to measure the maximum bounds to lock the slider to, when attempting to drag to the left.
         *
         * This will prevent users from being able to drag the last item all the way to the left, leaving a potentially large empty space.
         *
         * The logic behind this is to calculate which snap point can safely display as many items as possible, with the last item being visible.
         */
        const sliderItemSums = sliderItemSizeCopy
          .reverse()
          .reduce((result, item, index) => {
            if (index === 0) {
              result.push(item.width);
            } else {
              result.push(item.width + result[index - 1]);
            }

            return result;
          }, [] as number[])
          .reverse();

        // Find the sum that's closest to the width of the visible container, so we know which element can be the last one to snap to.
        const lastAllowedSnapPoint = getClosestNumber(
          sliderItemSums,
          sliderContainerWidth
        );

        // Find the index of the last allowed snap point, which will correlate to the value in the snapPoints array.
        let lastAllowedSnapPointIndex = sliderItemSums.findIndex(
          (value) => value === lastAllowedSnapPoint
        );

        // If the last allowed snap point causes the total visible item width to overflow the available container width, increment the index by 1, to prevent the last item from potentially being partially cut off
        if (
          lastAllowedSnapPoint >
            sliderContainerWidth - initialPosition.current &&
          lastAllowedSnapPointIndex !== snapPoints.current.length - 1
        ) {
          lastAllowedSnapPointIndex += 1;
        }

        return {
          right: initialPosition.current,
          left: snapPoints.current[lastAllowedSnapPointIndex], // Now we can define the furthest snap point to allow before rubberbanding back
        };
      },
      rubberband: true,
      filterTaps: true,
      axis: "x",
    }
  );

  const scrollPrev = () => {
    sliderIndex.current -= 1;
    springApi.start({ x: snapPoints.current[sliderIndex.current] });
  };

  const scrollNext = () => {
    sliderIndex.current += 1;
    springApi.start({ x: snapPoints.current[sliderIndex.current] });
  };

  return {
    sliderContainerRef: setSliderContainer,
    firstItemIsVisible,
    lastItemIsVisible,
    scrollPrev,
    scrollNext,
    animationStyles,
    dragAttributes,
  };
};
