import { useState, useEffect, FC } from "react";

import { usePostAnalyticsEvent } from "../../hooks/usePostAnalyticsEvent";
import useRecentQueries from "../../hooks/useRecentQueries";
import useResponsive from "../../hooks/useResponsive";
import { useAppDispatch } from "../../redux/hooks";
import { useFeatureSearchParams } from "../../hooks/useFeatureSearchParams";
import { useQuickSearchTags } from "../../hooks/useQuickSearchTags";

import { AnalyticsEvent } from "../../utils/analyticsTypes";
import { push } from "../../utils/navigate";
import { QueryParams, QueryParamBinary } from "../../utils/types";

import { RECENT_SEARCHES_KEY } from "../../constants";

import SearchTags, { Category } from "../../components/SearchTags/SearchTags";

import styles from "./SearchTagsContainer.module.scss";

interface Props {
  onClose: () => void;
}

const SearchTagsContainer: FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { Mobile } = useResponsive();

  const { query, isQuickSearch } = useFeatureSearchParams();

  const { quickSearchTags, getQuickSearchTagFromId } = useQuickSearchTags();

  const { addRecentQuery } = useRecentQueries(RECENT_SEARCHES_KEY);

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  const [activeQuickSearchTag, setActiveQuickSearchTag] =
    useState<Category | null>(null);

  const handleOnClick = (search: Category) => {
    if (activeQuickSearchTag === search) {
      onClose();
    } else {
      setActiveQuickSearchTag(search);
      addRecentQuery(String(search.id), { quickSearch: true });

      logAnalyticsEvent({
        event_type: AnalyticsEvent.SEARCH_QUERY_SEND,
        event_data: {
          query: getQuickSearchTagFromId(search.id),
          quick_search: true,
          quick_search_id: search.id,
        },
      });

      dispatch(
        push({
          pathOrLocation: "/search",
          newQueryParams: {
            [QueryParams.QUERY]: search?.id,
            [QueryParams.QUICK_SEARCH]: QueryParamBinary.ENABLED,
          },
          discardParams: [QueryParams.FEATURE_ID, QueryParams.NAME_SEARCH],
        }),
      );
    }
  };

  useEffect(() => {
    if (isQuickSearch && query) {
      const activeQSTag = quickSearchTags.find(
        (tag) => String(tag.id) === query,
      );
      if (activeQSTag) setActiveQuickSearchTag(activeQSTag);
    } else {
      setActiveQuickSearchTag(null);
    }
  }, [quickSearchTags, isQuickSearch, query]);

  return quickSearchTags.length ? (
    <div className={styles.quickSearchTags}>
      <SearchTags
        dataQA="search-tags"
        enableArrows={!Mobile}
        data={quickSearchTags}
        active={activeQuickSearchTag}
        onClick={handleOnClick}
      />
    </div>
  ) : null;
};

export default SearchTagsContainer;
