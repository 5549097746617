import { useState } from "react";
import {
  TransportFeed,
  TransportMode,
  TransportService,
} from "../redux/services/types";

export type TransportInformation = {
  transportMode: TransportMode;
  isTimetableData: boolean;
  services: TransportService[];
};

export type TransportIdentifier = {
  code: string;
  colour: string;
};

export const useTransportService = (
  transportFeeds: TransportFeed[] | undefined,
) => {
  const [selectedTransportService, setSelectedTransportService] = useState<
    string | null
  >(null);

  const handleTransportDepartureClick = (
    transportIdentifier: TransportIdentifier,
  ) => {
    setSelectedTransportService(transportIdentifier.code);
  };

  const transportInformation: TransportInformation | undefined =
    transportFeeds && {
      transportMode: transportFeeds[0].transportMode,
      isTimetableData: transportFeeds[0].isTimetableData,
      services: selectedTransportService
        ? transportFeeds[0].services.filter(
            (service) => service.lineName === selectedTransportService,
          )
        : transportFeeds[0].services,
    };

  const transportIdentifiers: TransportIdentifier[] | undefined =
    transportFeeds &&
    transportFeeds[0].services.map((service) => ({
      code: service.lineName,
      colour: service.lineColour,
    }));

  return {
    transportInformation,
    transportIdentifiers,
    handleTransportDepartureClick,
    selectedTransportService,
    setSelectedTransportService,
  };
};
