/*
 * First Event Listener is on the registered event listener and the 'updatefound' emitted event. This detects if a new service worker is available.
 * The first if statement is a fallback for if the `onupdatefound` call is missed as seems to happen with Safari and Firefox browsers.
 * Second Event Listener is on the new event listener and the 'statechange' emitted event. Switch on this state. If it is ready (installed), then call the updateServiceWorker function with the new worker.
 */
export function checkAndCreateServiceWorker() {
  window.addEventListener("load", async () => {
    if ("serviceWorker" in navigator) {
      const registration =
        await navigator.serviceWorker.register("/service-worker.js");

      registration.addEventListener("updatefound", () => {
        // A new service worker has been found
        const newWorker = registration.installing;
        newWorker?.addEventListener("statechange", () => {
          if (newWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // New service worker installed and ready to activate
              // Send a message to the service worker to skip waiting
              newWorker.postMessage({ type: "SKIP_WAITING" });
              // Reload the page to use the new service worker
              window.location.reload();
            } else {
              // No service worker currently controlling the page (first load)
              console.log("Service worker installed for the first time.");
            }
          }
        });
      });
    }
  });
}
