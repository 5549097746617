import { Icon } from "@livingmap/core-ui-v2";

import RouteStepBanner from "../../../../components/RouteStepBanner/RouteStepBanner";
import { useGetRouteMilestonesProps } from "../../../../hooks/useGetRouteMilestonesProps";
import { RouteMilestoneFeature } from "../../../../redux/services/types";

import styles from "./RouteContent.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  routeMilestones: RouteMilestoneFeature[];
  totalTime: number;
  showTime?: boolean;
}

export default function RouteContent({
  routeMilestones,
  totalTime,
  showTime,
}: Props) {
  const { t } = useTranslation();

  const routeMilestonesProps = useGetRouteMilestonesProps({
    routeMilestones,
    totalTime,
  });

  return (
    <>
      <div className={styles.warning}>
        <Icon
          type="WarningIcon"
          dataQA="warning-icon"
          className={styles.icon}
        />
        <p className={styles.text}>{t("navigate_page.caution_message")}</p>
      </div>
      <div className={styles.routeMilestones}>
        {routeMilestonesProps.map((props, index) => (
          <RouteStepBanner
            {...props}
            className={styles.milestone}
            dataQA="route-step-banner-item"
            key={index}
            time={showTime ? props.time : undefined}
          />
        ))}
      </div>
    </>
  );
}
