import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  FALLBACK_LANGUAGE,
  LOCIZE_PROJECT_ID,
  LOCIZE_VERSION,
} from "./constants";

const locizeOptions = {
  projectId: LOCIZE_PROJECT_ID,
  version: LOCIZE_VERSION,
  referenceLng: FALLBACK_LANGUAGE,
};

i18n
  .use(Backend) // Load translations through http (e.g., from a server)
  .use(LanguageDetector) // Detect language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    lng: FALLBACK_LANGUAGE,
    interpolation: { escapeValue: false }, // React already safe from XSS
    backend: locizeOptions,
    // Other options...
  });

export default i18n;
