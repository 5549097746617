/**
 *
 * @param array
 * @param value
 * @returns the closest number in the array based on the given value
 */
export const getClosestNumber = (array: number[], value: number) => {
  return array.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  );
};
