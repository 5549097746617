// LoadingChip.tsx
import { FC } from "react";
import ReactDOM from "react-dom";
import { Chip, Spinner } from "@livingmap/core-ui-v2";
import styles from "../SearchResultsView.module.scss";

interface Props {
  chipContainerRef: HTMLDivElement | null;
  isFetching: boolean;
  title?: string;
}

const LoadingChip: FC<Props> = ({
  chipContainerRef,
  isFetching,
  title = "Searching",
}) => {
  if (!isFetching) return null;

  return (
    chipContainerRef &&
    ReactDOM.createPortal(
      <Chip
        dataQA="loading-search-results"
        onClick={() => {}}
        className={styles.loadingChip}
        rounded
      >
        <span className={styles.spinner}>
          <Spinner dataQA="loading-spinner" size={4} type="BeatLoader" />
        </span>
        {title}
      </Chip>,
      chipContainerRef
    )
  );
};

export default LoadingChip;
