import { FC } from "react";
import { FeatureQueryResponse } from "../../../../redux/services/types";
import RoutingListItemGroup from "../RoutingListItemGroup/RoutingListItemGroup";
import { parseLanguageObject } from "../../../../utils/parseLanguageObject";
import { truncateString } from "../../../../utils/truncateString";

import styles from "./SearchResults.module.scss";

interface Props {
  dataQA: string;
  className?: string;
  searchResults?: FeatureQueryResponse[];
  language: string;
  query: string;
  onSearchResultClick?: (id: number, name: string) => void;
}

const SearchResults: FC<Props> = ({
  dataQA,
  className,
  searchResults,
  language,
  query,
  onSearchResultClick,
}) => {
  return (
    <div data-qa={dataQA} className={className}>
      {searchResults && searchResults.length ? (
        <RoutingListItemGroup
          icon="LocationOutlineIcon"
          items={searchResults.map(
            ({ uid, street_address, information: { long_name } }) => {
              const truncatedName = long_name
                ? truncateString(parseLanguageObject(long_name, language) || "")
                : "";

              const truncatedAddress = street_address
                ? truncateString(street_address)
                : "";

              return {
                name: truncatedName,
                address: truncatedAddress,
                onClick: () =>
                  onSearchResultClick &&
                  onSearchResultClick(uid, truncatedName),
              };
            }
          )}
        />
      ) : (
        <div className={styles.noResultsContainer}>
          Nothing found for "{query}" please edit your search and try again.
        </div>
      )}
    </div>
  );
};

export default SearchResults;
