import { FC, useEffect, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import ListItemResult, {
  COLOURS,
} from "../../../../components/ListItemResult/ListItemResult";
import { LocationStatus } from "../../../../components/LocationButton/LocationButton";
import { RECENT_POIS_KEY } from "../../../../constants";
import useRecentQueries from "../../../../hooks/useRecentQueries";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { buildRoutingPath } from "../../../../utils/buildRoutingPath";
import { push } from "../../../../utils/navigate";
import { truncateString } from "../../../../utils/truncateString";
import { QueryParams, RoutingPath } from "../../../../utils/types";
import RoutingListItemGroup from "../RoutingListItemGroup/RoutingListItemGroup";
import { MapContext } from "../../../../contexts/MapContext";

import styles from "./RoutingQuickActions.module.scss";
import useResponsive from "../../../../hooks/useResponsive";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface Props {
  onChooseFromMapButtonClick?: () => void;
  recentItemsLimit?: number;
  showQuickActions?: boolean;
}

export const RoutingQuickActions: FC<Props> = ({
  onChooseFromMapButtonClick,
  recentItemsLimit = 10,
  showQuickActions = true,
}) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { Mobile: showChooseFromMap } = useResponsive();
  const { t } = useTranslation();

  const { fromName, fromId, toName, toId } = useParams();

  const { locationStatus, queryParamsConfig } = useAppSelector(
    (state) => state.application,
  );

  const { positionControl } = useContext(MapContext);

  const [navigatingFromPosition, setNavigatingFromPosition] = useState(false);
  const [showNavigateFromPosition, setShowNavigateFromPosition] =
    useState(true);

  const { parsedRecentQueries } = useRecentQueries(RECENT_POIS_KEY, 10);

  function handleRecentPoiClick(id: string, name?: string) {
    dispatch(
      push({
        pathOrLocation: state?.selectDestination
          ? buildRoutingPath(RoutingPath.NAVIGATE, {
              fromName,
              fromId,
              toName: name,
              toId: id,
            })
          : buildRoutingPath(RoutingPath.NAVIGATE, {
              fromName: name,
              fromId: id,
              toName,
              toId,
            }),
        discardParams: [QueryParams.QUERY],
      }),
    );
  }

  useEffect(() => {
    setShowNavigateFromPosition(
      locationStatus === LocationStatus.FOUND &&
        queryParamsConfig["geolocation"] !== "disable",
    );
  }, [locationStatus, queryParamsConfig]);

  useEffect(() => {
    const position = positionControl?.getCurrentPosition();
    if (
      navigatingFromPosition &&
      locationStatus === LocationStatus.FOUND &&
      position
    ) {
      const coords = `${position.longitude},${position.latitude}`;
      const options = state?.selectDestination
        ? { fromName, fromId, toId: coords }
        : { fromId: coords, toName, toId };

      dispatch(
        push({
          pathOrLocation: buildRoutingPath(RoutingPath.NAVIGATE, options),
          discardParams: [QueryParams.QUERY],
        }),
      );
    }
  }, [
    dispatch,
    toId,
    toName,
    fromId,
    fromName,
    positionControl,
    state?.selectDestination,
    navigatingFromPosition,
    locationStatus,
  ]);

  return (
    <div className={styles.container}>
      {showQuickActions && (showNavigateFromPosition || showChooseFromMap) && (
        <>
          <div className={classNames(styles.quickActions)}>
            {showNavigateFromPosition && (
              <ListItemResult
                onClick={() => setNavigatingFromPosition(true)}
                dataQA="choose-current-location-list-item"
                title={t("route_page.your_location_button")}
                color={COLOURS.BLUE}
                icon="NearMeIcon"
                className={styles.quickActionButton}
              />
            )}
            {showChooseFromMap && (
              <ListItemResult
                onClick={onChooseFromMapButtonClick}
                dataQA="choose-from-map-list-item"
                title={t("route_page.choose_from_map_button")}
                color={COLOURS.BLUE}
                icon="LocationIcon"
              />
            )}
          </div>
          <div className={styles.divider} />
        </>
      )}
      <div
        className={classNames(styles.recentPois, {
          [styles.noMarginTop]:
            !showQuickActions ||
            (!showChooseFromMap && !showNavigateFromPosition),
        })}
      >
        <RoutingListItemGroup
          icon="HistoryIcon"
          items={parsedRecentQueries
            .sort((a, b) => b.key - a.key)
            .map(({ name, address, value }) => {
              const truncatedName = name ? truncateString(name) : "";
              const truncatedAddress = address ? truncateString(address) : "";
              return {
                name: truncatedName,
                address: truncatedAddress,
                onClick: () => handleRecentPoiClick(value, name),
              };
            })
            .slice(0, recentItemsLimit)}
        />
      </div>
    </div>
  );
};

export default RoutingQuickActions;
