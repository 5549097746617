import { useMemo } from "react";

import { Category } from "../components/SearchTags/SearchTags";
import { useAppSelector } from "../redux/hooks";
import { useGetMapConfigQuery } from "../redux/services/vectormapsAPI";

import { parseLanguageObject } from "../utils/parseLanguageObject";

export const useQuickSearchTags = () => {
  const { language } = useAppSelector((state) => state.application);

  const { data: mapData } = useGetMapConfigQuery();

  const quickSearchTags = useMemo(
    () =>
      mapData?.search_tags.map(
        (tag) =>
          ({
            id: tag.id,
            name: parseLanguageObject(tag.name, language),
          }) as Category,
      ) || [],
    [language, mapData?.search_tags],
  );

  const getQuickSearchTagFromId = (id: string | number) =>
    quickSearchTags.find((tag) => String(tag.id) === String(id))?.name || "";

  return { quickSearchTags, getQuickSearchTagFromId };
};
