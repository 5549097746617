import LocatorAPI from "./LocatorAPI";

interface LocatorLoggerInit {
  project: string;
  token: string;
  locatorHost: string;
}

interface AssetConfig {
  assetUuid: string;
  assetCategoryUuid: string;
  primaryAssetParameterCategoryUuid: string;
}

export class LocatorLogger {
  private readonly project: string;
  private readonly locatorApi: LocatorAPI;

  private initialisedPromise: Promise<boolean>;
  private resolveReadinesss: (val: boolean) => void = (_: boolean) => {};
  private rejectReadiness: (err: any) => void = (_: any) => {};

  private assetConfig: AssetConfig | null = null;
  private initialised: boolean = false;

  constructor(init: LocatorLoggerInit) {
    const { project, token, locatorHost } = init;

    this.locatorApi = new LocatorAPI(locatorHost, token);
    this.project = project;

    this.initialisedPromise = new Promise<boolean>((res, rej) => {
      this.resolveReadinesss = res;
      this.rejectReadiness = rej;
    });
  }

  private async waitForReadiness(): Promise<boolean> {
    return this.initialisedPromise;
  }

  public async init(): Promise<void> {
    if (this.initialised) {
      console.warn("LocatorLogger.init called more than once");
      return;
    }
    this.initialised = true;

    try {
      const assetCategories = await this.locatorApi.getAssetCategories(
        this.project,
      );

      if (assetCategories.length < 1) {
        throw new Error(
          "Misconfigured asset categories for locator logging project",
        );
      }
      const assetCategoryUuid = assetCategories[0].uuid;
      const parameterCategoryUuid = assetCategories[0].parameterCategories.find(
        (pCat) => pCat.primary,
      )?.uuid;

      if (!parameterCategoryUuid) {
        throw new Error(
          `Misconfigured asset category parameter categories for locator logging project - asset category ${assetCategoryUuid} has no primary parameter category`,
        );
      }

      const assetUuid = await this.locatorApi.postAsset(this.project, {
        assetCategoryUUID: assetCategoryUuid,
        parameters: {
          [parameterCategoryUuid]: "Locator Logging Anonymous Asset",
        },
        metadata: {
          logAsset: "true",
        },
      });

      this.assetConfig = {
        assetUuid,
        assetCategoryUuid,
        primaryAssetParameterCategoryUuid: parameterCategoryUuid,
      };
    } catch (e) {
      this.rejectReadiness(e);
      return;
    }

    this.resolveReadinesss(true);
  }

  public async log(
    pos: GeolocationPosition,
    extraMetadata?: { [key: string]: string },
  ): Promise<void> {
    await this.waitForReadiness();

    if (!this.assetConfig) {
      throw new Error("Must call LocatorLogger.init first");
    }

    const {
      accuracy,
      altitude,
      altitudeAccuracy,
      heading,
      latitude,
      longitude,
      speed,
    } = pos.coords;

    await this.locatorApi.postAssetPing(
      this.project,
      this.assetConfig.assetUuid,
      {
        position: {
          latitude,
          longitude,
        },
        timestamp: new Date(pos.timestamp).toISOString(),
        accuracy: accuracy,
        heading: heading,
        metadata: {
          speed: speed && speed.toFixed(2),
          altitude: altitude && altitude.toFixed(2),
          altitudeAccuracy: altitudeAccuracy && altitudeAccuracy.toFixed(2),
          ...extraMetadata,
        },
      },
    );
  }
}
