import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PluginRequest } from "../../utils/pluginSelect";
import { FlightConnectorDetails } from "./types";

import { ENVIRONMENT, PROJECT } from "../../constants";

export const flightConnectorAPI = createApi({
  reducerPath: "flightConnectorAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://flightconnector-api.${ENVIRONMENT}.livingmap.com`,
  }),
  endpoints: (builder) => ({
    getFlightFullDetails: builder.query<FlightConnectorDetails, PluginRequest>({
      query: ({ departure, arrival }) => ({
        url: `/v1/details`,
        params: {
          project: PROJECT
            ? PROJECT
            : encodeURIComponent(window.location.hostname),
          departure,
          arrival,
        },
      }),
    }),
  }),
});

export const { useLazyGetFlightFullDetailsQuery } = flightConnectorAPI;

export default flightConnectorAPI;
