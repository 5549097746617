import { useMatch } from "react-router-dom";
import { Path } from "../utils/types";

export const useRouteMatch = () => {
  const isOnFeatureInformationScreen = useMatch(Path.FEATURE);
  const isOnJourneyOverviewScreen = useMatch(Path.JOURNEY_OVERVIEW);
  const isOnJourneyScreen = useMatch(Path.JOURNEY);
  const isOnRouteScreen = useMatch(Path.NAVIGATE);
  const isOnRoutingScreen = useMatch(Path.ROUTE);

  return {
    isOnFeatureInformationScreen,
    isOnJourneyOverviewScreen,
    isOnJourneyScreen,
    isOnRouteScreen,
    isOnRoutingScreen,
  };
};
