import { useEffect, useCallback } from "react";

import { useAppSelector } from "../redux/hooks";
import { usePostEventMutation } from "../redux/services/vectormapsAPI";

import { AnalyticsEvents } from "./../utils/analyticsTypes";

export const usePostAnalyticsEvent = () => {
  const { project, sessionId } = useAppSelector((state) => state.application);

  const [postEvent, { isError }] = usePostEventMutation();

  const handlePostEvent = useCallback(
    (data: AnalyticsEvents) => postEvent({ project, uuid: sessionId, data }),
    [postEvent, project, sessionId],
  );

  useEffect(() => {
    if (isError) {
      console.error("Error posting analytics event");
    }
  }, [isError]);

  return { logAnalyticsEvent: handlePostEvent };
};
