import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  FeatureQueryResponse,
  MapConfigResponse,
  RouteLocation,
  SearchResultsQueryResponse,
  ConfigFeatureNamesResponse,
  EventPostResponse,
  RouteOptions,
  RouteQueryResponse,
  SessionPostResponse,
} from "./types";
import { AnalyticsEvents } from "../../utils/analyticsTypes";

import { PROJECT } from "../../constants";

export const vectorMapsAPI = createApi({
  reducerPath: "vectorMapsApi",
  tagTypes: ["Config", "Feature", "SearchResults", "FeatureNames", "Route"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getMapConfig: builder.query<MapConfigResponse, void>({
      query: () =>
        PROJECT
          ? `/maps/${PROJECT}`
          : `/maps?host=${encodeURIComponent(window.location.hostname)}`,
      providesTags: ["Config"],
    }),
    getFeatureById: builder.query<
      FeatureQueryResponse,
      { featureId: string; project?: string }
    >({
      query: ({ featureId, project }) =>
        `/maps/${project}/features/${featureId}`,
      providesTags: ["Feature"],
    }),
    getFeaturesBySearch: builder.query<
      SearchResultsQueryResponse,
      {
        query: string;
        limit?: number;
        longitude?: number;
        latitude?: number;
        floor_id?: number;
        project?: string;
        lang?: string;
      }
    >({
      query: ({
        query,
        latitude,
        limit = 20,
        longitude,
        project,
        floor_id,
        lang,
      }) => ({
        url: `/maps/${project}/search`,
        params: { query, latitude, longitude, floor_id, limit, lang },
      }),
      providesTags: ["SearchResults"],
    }),
    getFeaturesByTagSearch: builder.query<
      SearchResultsQueryResponse,
      {
        id: string;
        limit?: number;
        longitude?: number;
        latitude?: number;
        floor_id?: number;
        project?: string;
      }
    >({
      query: ({ id, latitude, limit = 20, longitude, floor_id, project }) => ({
        url: `/maps/${project}/search/tag/${id}`,
        params: { latitude, longitude, limit, floor_id },
      }),
      providesTags: ["SearchResults"],
    }),
    getFeaturesByName: builder.query<
      SearchResultsQueryResponse,
      { longName: string; project?: string; lang?: string }
    >({
      query: ({ longName, project, lang }) => ({
        url: `/maps/${project}/features/?long_name=${encodeURIComponent(longName)}&lang=${lang}`,
      }),
      providesTags: ["SearchResults"],
    }),
    getSearchFeatureNames: builder.query<
      ConfigFeatureNamesResponse,
      { project?: string; lang?: string }
    >({
      query: ({ project, lang }) => ({
        url: `/maps/${project}/feature-names`,
        params: { lang },
      }),
      providesTags: ["FeatureNames"],
    }),
    postRoute: builder.query<
      RouteQueryResponse,
      {
        from: RouteLocation;
        to: RouteLocation;
        via?: RouteLocation[];
        options?: RouteOptions;
        project?: string;
      }
    >({
      query: ({ from, to, via, options, project }) => ({
        url: `/route`,
        method: "POST",
        body: { from, to, via, options, project },
      }),
      providesTags: ["Route"],
    }),
    postSession: builder.mutation<
      SessionPostResponse,
      {
        project?: string;
      }
    >({
      query: ({ project }) => ({
        url: `/maps/${project}/sessions`,
        method: "POST",
        body: { component: "map_ui" },
      }),
    }),
    postEvent: builder.mutation<
      EventPostResponse,
      {
        project?: string;
        uuid?: string;
        data: AnalyticsEvents;
      }
    >({
      query: ({ project, uuid, data }) => ({
        url: `/maps/${project}/sessions/${uuid}/events`,
        method: "POST",
        body: {
          event_type: data.event_type,
          ...("event_data" in data && { event_data: data.event_data }),
        },
      }),
    }),
  }),
});

export const {
  useGetMapConfigQuery,
  useGetFeatureByIdQuery,
  useGetFeaturesByTagSearchQuery,
  useGetFeaturesBySearchQuery,
  useGetFeaturesByNameQuery,
  useGetSearchFeatureNamesQuery,
  usePostRouteQuery,
  usePostSessionMutation,
  usePostEventMutation,
} = vectorMapsAPI;

export default vectorMapsAPI;
