// Import required modules and types
import { LMFeature } from "@livingmap/core-mapping";
import { SourceIds } from "../components/Map/plugins/types";
import { FeatureQueryResponse } from "../redux/services/types";
import { parseLanguageObject } from "./parseLanguageObject";

/**
 * Create an LMFeature object based on the given feature and floor data.
 *
 * @param {FeatureQueryResponse} feature - The feature data, usually obtained from a query response.
 * @param {Floor} floor - The floor data associated with the feature.
 * @returns {LMFeature} - The created LMFeature object.
 */
const createLMFeature = (
  feature: FeatureQueryResponse,
  language: string,
): LMFeature => {
  // Destructure the necessary properties from the input feature object
  const {
    location: {
      center: { latitude, longitude },
      floor,
    },
    id,
    type,
  } = feature;

  if (!floor) throw new Error("No floor data for feature");

  const floorWithName = {
    ...floor,
    name: parseLanguageObject(floor.name, language) || "NONE",
  };

  // Create a GeoJSON Point object for the feature's geometry
  const geom: GeoJSON.Point = {
    coordinates: [longitude, latitude],
    type: "Point",
  };

  const areaHighlight = feature.area_highlight?.properties
    ? new LMFeature({
        type: "Feature",
        geometry: feature.area_highlight.geometry,
        id: feature.area_highlight.id,
        properties: {
          lm_id: feature.area_highlight.properties.lm_id,
          uid: feature.area_highlight.id,
        },
        layer: { id: "custom", type: "symbol" },
        state: {},
        source: SourceIds.LM_SOURCE_ID,
        sourceLayer: type,
      })
    : undefined;

  // Instantiate an LMFeature object with the appropriate properties and configuration
  const lmFeature = new LMFeature({
    type: "Feature",
    geometry: geom,
    id: feature.uid,
    properties: {
      geom: geom,
      floor_id: floorWithName.id,
      floor_name: floorWithName.name,
      indoor: feature.type,
      lm_id: id,
      uid: feature.uid,
      is_geojson: true,
      geom_type: "DISPLAY_POINT",
      is_label_shown: true,
      name: parseLanguageObject(feature.information.long_name, language),
      area_highlight: areaHighlight,
    },
    layer: { id: "custom", type: "symbol" },
    state: {},
    source: SourceIds.LM_SOURCE_ID,
    sourceLayer: type,
  });

  // Return the created LMFeature object
  return lmFeature;
};

// Export the createLMFeature function as the default export
export default createLMFeature;
