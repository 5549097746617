import { useEffect, useState } from "react";

export default function useMotionAndOrientationPermission() {
  const [permissionRequested, setPermissionRequested] = useState(false);

  useEffect(() => {
    // function handleMotion(event: DeviceMotionEvent) {
    //   // TODO: Do something with the motion data
    // }

    // function handleOrientation(event: DeviceOrientationEvent) {
    //   // TODO: Do something with the orientation data
    // }

    if (permissionRequested) {
      if (
        typeof DeviceMotionEvent !== "undefined" &&
        // @ts-expect-error
        typeof DeviceMotionEvent.requestPermission === "function"
      ) {
        // @ts-expect-error
        DeviceMotionEvent.requestPermission();

        // TODO: add motion event listener
        // // @ts-expect-error
        // .then((permissionState) => {
        //   if (permissionState === "granted") {
        //     window.addEventListener("devicemotion", handleMotion, true);
        //   } else {
        //     console.warn("Permission not granted for DeviceMotion");
        //   }
        // })
        // .catch(console.error);
      }
      // else {
      //   window.addEventListener("devicemotion", handleMotion, true);
      // }
    }

    if (
      typeof DeviceOrientationEvent !== "undefined" &&
      // @ts-expect-error
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      // @ts-expect-error
      DeviceOrientationEvent.requestPermission();

      // TODO: add orientation event listener
      // // @ts-expect-error
      // .then((permissionState) => {
      //   if (permissionState === "granted") {
      //     window.addEventListener(
      //       "deviceorientation",
      //       handleOrientation,
      //       true,
      //     );
      //   } else {
      //     console.warn("Permission not granted for DeviceOrientation");
      //   }
      // })
      // .catch(console.error);
    }
    // else {
    //   window.addEventListener("deviceorientation", handleOrientation, true);
    // }

    // return () => {
    //   window.removeEventListener("devicemotion", handleMotion, true);
    //   window.removeEventListener("deviceorientation", handleOrientation, true);
    // };
  }, [permissionRequested]);

  function requestMotionAndOrientationPermission() {
    setPermissionRequested(true);
  }

  return { requestMotionAndOrientationPermission };
}
