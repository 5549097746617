import { MapContext } from "../contexts/MapContext";
import { useContext } from "react";

export const useMatchLocationCoords = () => {
  const { positionControl } = useContext(MapContext);

  const isMatchWithLocationCoords = (coords?: string[]) =>
    coords?.length === 2 &&
    coords[0] === positionControl?.getCurrentPosition()?.longitude.toString() &&
    coords[1] === positionControl?.getCurrentPosition()?.latitude.toString();

  return { isMatchWithLocationCoords };
};
