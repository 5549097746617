import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { usePostRouteQuery } from "../../../redux/services/vectormapsAPI";

import { QueryParamBinary, QueryParams } from "../../../utils/types";

export const usePostRouteData = ({
  fromId,
  toId,
  floorId = 0,
}: {
  fromId?: string;
  toId?: string;
  floorId?: number;
}) => {
  const [searchParams] = useSearchParams();

  const { project } = useAppSelector((state) => state.application);

  const stepFreeRoute =
    searchParams.get(QueryParams.STEP_FREE) === QueryParamBinary.ENABLED;

  const splitFromId = fromId?.split(",");
  const splitToId = toId?.split(",");

  const { data, isFetching, isError, isSuccess } = usePostRouteQuery(
    {
      from:
        splitFromId?.length === 2
          ? {
              longitude: Number(splitFromId[0]),
              latitude: Number(splitFromId[1]),
              floorId,
            }
          : { featureId: Number(fromId) },
      to:
        splitToId?.length === 2
          ? {
              longitude: Number(splitToId[0]),
              latitude: Number(splitToId[1]),
              floorId,
            }
          : { featureId: Number(toId) },
      ...(stepFreeRoute ? { options: { routeModifier: "step_free" } } : {}),
      project,
    },
    {
      skip: fromId === "''" || toId === "''" || !floorId || !project,
    },
  );

  return { data, isFetching, isError, isSuccess, stepFreeRoute };
};
