import {
  useGetFeaturesBySearchQuery,
  useGetFeaturesByTagSearchQuery,
  useGetFeaturesByNameQuery,
} from "../../../redux/services/vectormapsAPI";
import { useAppSelector } from "../../../redux/hooks";
import { useFeatureSearchParams } from "../../../hooks/useFeatureSearchParams";

import { FloorConfig } from "./../../../components/Map/plugins/floor-control";
import { MapCentre } from "../../../components/Map/Map";

const useSearchResults = ({
  activeFloor,
  mapCentre,
}: {
  quickSearch?: boolean;
  nameSearch?: boolean;
  activeFloor?: FloorConfig | null;
  mapCentre?: MapCentre;
}) => {
  const { project, language } = useAppSelector((state) => state.application);

  const { query, isQuickSearch, isNameSearch } = useFeatureSearchParams();

  const { isFetching: isFetchingBySearchQuery, data: featuresBySearchQuery } =
    useGetFeaturesBySearchQuery(
      {
        query,
        ...(mapCentre &&
          activeFloor && {
            latitude: mapCentre.lat,
            longitude: mapCentre.lng,
            floor_id: activeFloor.id,
          }),
        project,
        lang: language,
      },
      {
        skip: isQuickSearch || isNameSearch || !query || !project,
      },
    );

  const { isFetching: isFetchingByNameQuery, data: featuresByNameQuery } =
    useGetFeaturesByNameQuery(
      {
        longName: query,
        project,
        lang: language,
      },
      {
        skip: isQuickSearch || !isNameSearch || !query || !project,
      },
    );

  const { isFetching: isFetchingBySearchTag, data: featuresBySearchTag } =
    useGetFeaturesByTagSearchQuery(
      {
        id: query,
        ...(mapCentre &&
          activeFloor && {
            latitude: mapCentre.lat,
            longitude: mapCentre.lng,
            floor_id: activeFloor.id,
          }),
        project,
      },
      {
        skip:
          !isQuickSearch ||
          isNameSearch ||
          !query ||
          !project ||
          !activeFloor ||
          !mapCentre,
      },
    );

  return {
    query,
    featureData: isQuickSearch
      ? featuresBySearchTag?.data
      : isNameSearch
        ? featuresByNameQuery?.data
        : featuresBySearchQuery?.data,
    isFetching: isQuickSearch
      ? isFetchingBySearchTag
      : isNameSearch
        ? isFetchingByNameQuery
        : isFetchingBySearchQuery,
  };
};

export default useSearchResults;
