import { useAppSelector } from "../../../redux/hooks";
import { useGetTransportFeedsByTransportStringQuery } from "../../../redux/services/transportAPI";
import { useGetFeatureByIdQuery } from "../../../redux/services/vectormapsAPI";

const useFeatureData = (id: string | null) => {
  const { project } = useAppSelector((state) => state.application);
  const featureId = id || "";

  const {
    isSuccess: isFeatureSuccess,
    isFetching: isFeatureFetching,
    data: featureData,
  } = useGetFeatureByIdQuery({ featureId, project }, { skip: !id || !project });

  const {
    isSuccess: isTransportSuccess,
    isFetching: isTransportFetching,
    data: transportFeeds,
  } = useGetTransportFeedsByTransportStringQuery(featureData?.transport_api, {
    skip: !featureData?.transport_api,
  });

  return {
    featureData: isFeatureSuccess && !isFeatureFetching ? featureData : null,
    transportFeeds:
      featureData?.transport_api && isTransportSuccess && !isTransportFetching
        ? transportFeeds
        : undefined,
  };
};

export default useFeatureData;
