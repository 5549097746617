import { UAParser } from "ua-parser-js";
import { setDeviceInformation } from "../redux/slices/applicationSlice";
import { useAppDispatch } from "../redux/hooks";
import { useEffect } from "react";

export const useDeviceInfo = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const parser = new UAParser(navigator.userAgent);
    const { os } = parser.getResult();

    dispatch(
      setDeviceInformation({
        os: os.name,
        osVersion: os.version,
      }),
    );
  }, [dispatch]);
};
