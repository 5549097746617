import { History } from "history";
import queryString from "query-string";
import {
  push as reduxPush,
  replace as reduxReplace,
} from "redux-first-history";
import { CallHistoryMethodAction } from "redux-first-history/build/es6/actions";
import { Location } from "react-router-dom";
import { QueryParams } from "./types";

const getSearchString = (
  pathOrLocation: string | Location,
  newQueryParams: {},
  discardParams: string[] = []
) => {
  // If a location object is passed, extract pathname, search, and hash
  let pathname = pathOrLocation;
  let search = window.location.search;
  let hash = window.location.hash;

  if (typeof pathOrLocation !== "string") {
    pathname = pathOrLocation.pathname;
    search = pathOrLocation.search;
  }

  // Parse the current query string
  const currentQueryParams = queryString.parse(search);

  // Filter current query params to exclude ones that should be discarded
  const preservedQueryParams = Object.keys(currentQueryParams)
    .filter((key) => !discardParams.includes(key))
    .reduce((obj: { [key: string]: any }, key) => {
      obj[key] = currentQueryParams[key];
      return obj;
    }, {});

  // Merge current and new query params
  const mergedQueryParams = {
    ...preservedQueryParams,
    ...newQueryParams,
  };

  // Stringify back to query string
  return {
    pathname,
    searchString: queryString.stringify(mergedQueryParams),
    hash,
  };
};

interface NavigationParams {
  pathOrLocation: string | Location;
  newQueryParams?: { [key in QueryParams]?: string | number };
  state?: { [key: string]: any };
  discardParams?: QueryParams[];
}

export const push = ({
  pathOrLocation,
  newQueryParams = {},
  state,
  discardParams = [],
}: NavigationParams): CallHistoryMethodAction<Parameters<History["push"]>> => {
  const { pathname, searchString, hash } = getSearchString(
    pathOrLocation,
    newQueryParams,
    discardParams
  );
  return reduxPush(`${pathname}?${searchString}${hash}`, state);
};

export const replace = ({
  pathOrLocation,
  newQueryParams = {},
  state,
  discardParams = [],
}: NavigationParams): CallHistoryMethodAction<
  Parameters<History["replace"]>
> => {
  const { pathname, searchString, hash } = getSearchString(
    pathOrLocation,
    newQueryParams,
    discardParams
  );

  return reduxReplace(`${pathname}?${searchString}${hash}`, state);
};
