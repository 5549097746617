import { useParams } from "react-router-dom";

import { useMatchLocationCoords } from "./useMatchLocationCoords";

import { formatLatLng } from "../utils/formatLatLng";

import { OriginDestinationLocation } from "../components/RouteInput/RouteInput";
import { useTranslation } from "react-i18next";

const useGetRouteInputData = ({
  origin,
  destination,
}: {
  origin?: string;
  destination?: string;
}) => {
  const { fromId, toId } = useParams();
  const { t } = useTranslation();

  const { isMatchWithLocationCoords } = useMatchLocationCoords();

  const fromCoords = fromId?.split(",");
  const toCoords = toId?.split(",");

  const getType = (coords?: string[]) =>
    isMatchWithLocationCoords(coords) ? "SYSTEM_DEFINED" : "USER_DEFINED";

  const getData = (defaultName?: string, coords?: string[]) =>
    isMatchWithLocationCoords(coords)
      ? t("route_page.your_location_button")
      : coords?.length === 2
        ? formatLatLng(coords)
        : defaultName !== "''"
          ? defaultName
          : "";

  const getOriginRouteData = (): OriginDestinationLocation => ({
    type: getType(fromCoords),
    data: getData(origin, fromCoords),
  });

  const getDesintationRouteData = (): OriginDestinationLocation => ({
    type: getType(toCoords),
    data: getData(destination, toCoords),
  });

  return { getOriginRouteData, getDesintationRouteData };
};

export default useGetRouteInputData;
