import { animated } from "react-spring";
import classNames from "classnames";
import { Chip, FloatingIconButton } from "@livingmap/core-ui-v2";

import styles from "./SearchTags.module.scss";
import { useSlider, SliderOptions } from "../../hooks/useSlider";

export type Category = {
  id: number;
  name: string;
};

interface Props extends SliderOptions {
  dataQA: string;
  data: Category[];
  className?: string;
  onClick: (selection: Category) => void;
  active?: Category | null;
}

const SearchTags: React.FC<Props> = ({
  dataQA,
  data,
  enableArrows,
  initialOffset,
  itemGap,
  className,
  onClick,
  active = null,
}) => {
  const {
    sliderContainerRef,
    firstItemIsVisible,
    lastItemIsVisible,
    scrollPrev,
    scrollNext,
    animationStyles,
    dragAttributes,
  } = useSlider({ enableArrows, initialOffset, itemGap });

  const handleOnClick = (selection: Category) => {
    onClick(selection);
  };

  return (
    <div data-qa={dataQA} className={classNames(styles.container, className)}>
      {enableArrows && !firstItemIsVisible && (
        <FloatingIconButton
          dataQA="arrow-left"
          icon="ChevronLeftIcon"
          className={styles.arrowLeft}
          size="small"
          rounded
          onClick={scrollPrev}
        />
      )}
      {enableArrows && !lastItemIsVisible && (
        <FloatingIconButton
          dataQA="arrow-right"
          icon="ChevronRightIcon"
          className={styles.arrowRight}
          size="small"
          rounded
          onClick={scrollNext}
        />
      )}
      <div className={styles.sliderContainer}>
        <div style={{ position: "relative" }}>
          <animated.div
            ref={sliderContainerRef}
            style={{ ...animationStyles, touchAction: "none" }}
            className={styles.slider}
            {...dragAttributes()}
          >
            {data.map((item) => (
              <div key={item.id} className={styles.tagWrapper}>
                <Chip
                  dataQA="search-tag"
                  onClick={() => handleOnClick(item)}
                  className={classNames(styles.tag, {
                    [styles.active]: active && item.id === active.id,
                  })}
                  active={active ? item.id === active.id : false}
                  rounded
                >
                  {item.name}
                </Chip>
              </div>
            ))}
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default SearchTags;
