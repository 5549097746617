import { FC, ReactNode } from "react";
import { Icon, IconType } from "@livingmap/core-ui-v2";

import styles from "./MetaData.module.scss";

interface Props {
  dataQA: string;
  icon: IconType;
  children: ReactNode;
}

const MetaData: FC<Props> = ({ icon, dataQA, children }) => {
  return (
    <div data-qa={dataQA} className={styles.container}>
      <div>
        <Icon dataQA="icon" type={icon} className={styles.icon} />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default MetaData;
