import { RefObject, useContext } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useGetMapConfigQuery } from "../redux/services/vectormapsAPI";
import { clearLocation } from "../redux/slices/applicationSlice";
import { usePostAnalyticsEvent } from "./usePostAnalyticsEvent";
import useRecentQueries from "./useRecentQueries";
import { useSearchQuery } from "./useSearchQuery";

import { AnalyticsEvent } from "../utils/analyticsTypes";
import { push } from "../utils/navigate";
import { parseLanguageObject } from "../utils/parseLanguageObject";
import { QueryParam, QueryParams } from "../utils/types";

import { RECENT_SEARCHES_KEY } from "../constants";

import { MapContext } from "../contexts/MapContext";
import { truncateString } from "../utils/truncateString";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  onChange?: (value: string) => void;
  onClose?: () => void;
  onKeyDown?: (key: string) => boolean;
  onSubmit?: () => void;
  ref: RefObject<HTMLInputElement>;
}

/**
 * Hook to get the props for the search bar
 */
export const useSearchBarProps = ({
  className,
  onChange,
  onClose,
  onKeyDown,
  onSubmit,
  ref,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { interactionControl, clusteredPinControl } = useContext(MapContext);

  const { language } = useAppSelector((state) => state.application);

  const { data: mapData } = useGetMapConfigQuery();

  const { searchQuery, clearSearchQuery } = useSearchQuery();

  const { addRecentQuery } = useRecentQueries(RECENT_SEARCHES_KEY);

  const { logAnalyticsEvent } = usePostAnalyticsEvent();

  if (!mapData) throw new Error("No mapData available");

  return {
    ref,
    className,
    value: searchQuery,
    placeholder: truncateString(
      t("home.search_bar_text", {
        projectName: parseLanguageObject(mapData.name, language),
      }),
      35,
    ),
    onClose: () => {
      clearSearchQuery();
      interactionControl?.deselectFeatures();
      clusteredPinControl?.clearClusteredPinSource();
      dispatch(clearLocation());
      dispatch(
        push({
          pathOrLocation: "/",
          discardParams: [
            QueryParams.FEATURE_ID,
            QueryParams.QUERY,
            QueryParams.QUICK_SEARCH,
            QueryParam.NAME_SEARCH,
          ],
        }),
      );

      onClose && onClose();
    },
    onSubmit: (value: string) => {
      onSubmit && onSubmit();

      addRecentQuery(value, { quickSearch: false });

      logAnalyticsEvent({
        event_type: AnalyticsEvent.SEARCH_QUERY_SEND,
        event_data: {
          query: value,
          quick_search: false,
          quick_search_id: null,
        },
      });

      dispatch(
        push({
          pathOrLocation: "/search",
          newQueryParams: { [QueryParams.QUERY]: value },
          discardParams: [
            QueryParams.FEATURE_ID,
            QueryParams.QUICK_SEARCH,
            QueryParam.NAME_SEARCH,
          ],
        }),
      );
    },
    ...(onKeyDown && { onKeyDown }),
    onChange: (value: string) => onChange && onChange(value),
  };
};
