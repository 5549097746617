import { createContext } from "react";

type FocusContextType = {
  originInputRef: React.RefObject<HTMLInputElement>;
  originInputFocused: boolean;
  setOriginInputFocused: (value: boolean) => void;
  destinationInputRef: React.RefObject<HTMLInputElement>;
  destinationInputFocused: boolean;
  setDestinationInputFocused: (value: boolean) => void;
};

export const RouteInputFocusContext = createContext<FocusContextType | null>(
  null
);
